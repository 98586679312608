// 芬兰语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Koti",
    },
    "searchLanguage": {
        searchBtn: 'Hae',
        searchPlace: 'Hae....'
    },
    'setDrawer': {
        label1: "Monikieliasetukset",
        label2: "Kauppias asettunut sisään",
        label3: 'Kauppiaan kirjautuminen',
        label4: 'asiakaspalvelu',
        label5: 'Lataa Android-sovellus',
        label6: 'Lataa ios-sovellus',
        optionsBtn: 'Peruuta'
    },
    "appService": {
        navTitle: 'Asiakaspalvelu',
        iconInformation: 'Valitse viestintäohjelmisto konsultaatiota varten',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: "Kauppias asettui sisään",
        label1: 'Kauppiaan nimi',
        label1Hint: "Kauppiaan nimi tai tuotenimi, kun se on asetettu, ei voi muokata",
        visible1: "Anna kauppiaan nimi",

        label2: 'Mailbox',
        label2Hint: 'Salasana, jota kauppias käyttää kirjautuessaan sisään kauppiasportaaliin. ',
        visible2: "Anna sähköpostiosoitteesi",
        label3: 'Sähköpostin vahvistuskoodi',
        label3Hint: 'Koodi tarvitaan vahvistamaan kauppiaan henkilöllisyys ja estämään haitallinen toiminta. Anna oikeat vahvistuskoodin tiedot',
        visible3: "Anna sähköpostin vahvistuskoodi",

        label4: 'Kauppiaan salasana',
        label4Hint: 'Salasana, jota kauppias käyttää kirjautuessaan sisään kauppiasportaaliin. ',
        visible4: "Anna kauppiaan salasana",
        label5: 'kutsukoodi',
        visible5: 'Anna kutsukoodi',
        label5Hint: 'Anna sinut kutsuneen kauppiaan kutsukoodi. ',

        label6: 'Store image',
        label6Hint: "Kauppiaan myymälän kuva",
        visible6: "Anna vahvistuskoodi",
        label7: 'Kauppiastodistus',
        label7Hint: 'Kauppiaan todistusasiakirjat, kuten kauppiaan lisenssi, verorekisteröintitodistus jne. Näiden tiedostojen tulee olla korkealaatuisia kuvatiedostoja. ',
        visible7: 'Lähetä kuvia kauppiaslisenssistäsi ja asiaankuuluvista alan pätevyystodistuksista (voit lähettää enintään 2 kuvaa, kunkin kuvan enimmäiskoko on 5 Mt ja kuvamuoto tukee Jpg- ja Png-tiedostoja)',

        placeholderEnter: 'Syötä',
        placeholderSelect: 'Valitse',
        sendEmail: 'Lähetä',
        optionBtn1: 'Palveluehdot',
        optionBtn2: 'Myyjän suoja',
        optionBtn3: "Lähetä tarkistettavaksi",
        uploadMessageError: 'Kuvan lataus epäonnistui',
        UploadLayoutError: 'Image format error',
        fileFail: 'Lähetä ja muotoile kuvat',
        popupTitle1: 'Käyttöehdot',
        popupTitle2: "Myyjän suojasopimus"
    },
    'appSetLanguage': {
        navTitle: 'Monikieliset asetukset',
        affirm: 'vahvista',
    },
    'classifyDrawer': {
        navTitle: 'Kategoria'
    },
    'appHome': {
        recommendTitle: 'Suosittele',
        viewAll: 'näytä kaikki',
        usedTitle: 'Käytetty',
        buyNow: "Osta nyt",
        shopTitle: "Kauppias",
        shopOption: "Closet",
        hotTitle: "Kuuma",
        ranking: "Myynnin ranking",
        preSaleTitle: 'Ennakkomyynti',
        preSaleTabs1: 'Jatkuva',
        preSaleTabs2: 'Ei aloitettu',
        preSaleTabs3: "Over",
        groupTitle: 'Ryhmä',
        peopleGroupBuying: "ihmiset osallistuivat",
        flashSaleTitle: "Flash",
        preSaleStateTimeS: 'start',
        preSaleStateTimeE: 'end',
        showNow: "Mene ostoksille nyt"
    },
    "appShopsListIndex": {
        navTitle: 'Kauppiasluettelo',
        searchPlace: 'Hae...',
        favoriteTitle: 'Suosikkimäärä &',
        shopBtn: "Näytä vaatekaappi",
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Category',
        viewAll: 'näytä kaikki',
    },

    'appClassProductIndex': {
        navTitle: 'Tuote',
        select1: 'Kattava',
        select2: 'Myyntimäärä',
        select3: 'Uusi tuote',
        select4: "Hinta korkeasta alhaiseen",
        select5: "Hinta alhaisesta korkeaan",
        select6: 'Rating',
        paymentTitle: 'Maksu',

    },
    'appPreSale': {
        navTitle: 'Ennakkomyynti',
        tabs1: 'Käynnissä',
        tabs2: 'Ei aloitettu',
        tabs3: 'Over',
        selectAll: 'Kaikki',
        listLabel: "Toimitus",
        listLabe2: 'day',
    },
    "appGroupBuying": {
        navTitle: 'Ryhmä',
        selectAll: 'Kaikki',
        listLabel: "Tarvitsee",
        listLabe2: 'ihmiset',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Kaikki',
    },
    'appSalesRanking': {
        navTitle: 'Kuuma',
        payment: 'maksu',
        optionBtn1: 'Kerää',
        optionBtn2: "Osta nyt",
    },
    'appLogin': {
        navTitle: 'Kirjaudu',
        navTitleHint: 'Kirjaudu sisään ja aloita kaupankäynti ystävien kanssa kaikkialta maailmasta! ',
        singUp: "Rekisteröidy",

        emailLabel: 'matkapuhelinnumero',
        emailPlaceholder: "Anna matkapuhelinnumerosi",
        emailErrorText: 'Virheilmoitus! ',
        emailNullVerify: 'Syötä matkapuhelinnumerosi!',//Anna sähköpostiosoitteesi
        emailErrorVerify: 'Anna matkapuhelinnumerosi! ',//Sähköpostin muotovirhe

        passwordLabel: 'salasanasi',
        passPlaceholder: 'Anna salasana',
        passNullVerify: 'Anna salasanasi', //Anna salasanasi
        passErrorVerify: 'Salasana on alle 6 merkkiä pitkä! ',//Salasana on alle 6 merkkiä pitkä

        placeholder: 'Syötä',

        emailRegistration: 'Rekisteröidy nyt',
        forgetPassTitle: 'Unohditko salasanasi? ',

        submitBtnTitle: "Kirjaudu sisään",
        loginMessage: 'Tili tai salasana on väärä! '
    },
    'appForgotPass': {
        navTitle: "Unohdin salasana",
        navTitleHint: 'Täytä matkapuhelinnumerosi ja vastaanota vahvistuskoodi uuden salasanan asettamiseksi. ',
        emailLabel: 'matkapuhelinnumero',
        emailPlaceholder: "Anna matkapuhelinnumerosi",
        emailErrorText: 'Virheilmoitus!',//Anna sähköpostiosoitteesi
        emailNullVerify: 'Syötä matkapuhelinnumerosi!',//Anna sähköpostiosoitteesi
        emailErrorVerify: "Syötä matkapuhelinnumerosi!",//Sähköpostin muotovirhe
        verificationCode: 'vahvistuskoodi',
        verificationPlace: 'vahvistuskoodi',
        verificationCodeError: 'Anna vahvistuskoodi! ',
        sendTitle: 'Lähetä vahvistuskoodi',
        sendText: 'Reverify',
        passwordLabel: 'Uusi salasana',
        passPlaceholder: "Anna uusi salasana",
        confirmPassLabel: 'Vahvista uusi salasana',
        confirmPlace: 'Vahvista uusi salasana',
        passNullVerify: 'Anna uusi salasanasi', //Anna salasanasi
        passErrorVerify: 'Salasana on alle 6 merkkiä pitkä! ',//Salasana on alle 6 merkkiä pitkä
        passCompareErrorVerify: 'Kahdesti annetut salasanat ovat epäjohdonmukaisia! ',
        passCompareErrorNull: 'Anna uusi salasana uudelleen',
        submitBtnTitle: 'Palauta salasana',
        loginMessage: 'Vahvistuskoodi on virheellinen! '
        // emailRegistration: 'Sähköpostirekisteröinti',
        // forgetPassTitle: 'Unohditko salasanan?',

    },
    'appRegister': {
        navTitle: 'Rekisteröidy',
        navTitleHint: 'Luo tili, jotta pääset käyttämään kaikkia ominaisuuksia! ',
        namePlace: 'Syötä',
        firstNameLabel: 'nimi',
        firstNamePlace: 'Syötä',
        firstNameErrorVerify: "Syötä nimesi",
        lastNameLabel: 'sukunimi',
        lastNameErrorVerify: "Anna sukunimesi",
        nickNameLabel: 'nickname',
        nickNameErrorVerify: "Anna lempinimesi",
        emailLabel: 'matkapuhelinnumero',
        emailPlaceholder: "Anna matkapuhelinnumerosi",
        emailErrorText: 'Virheilmoitus!',//Anna sähköpostiosoitteesi
        emailNullVerify: 'Syötä matkapuhelinnumerosi!',//Anna sähköpostiosoitteesi
        emailErrorVerify: 'Anna matkapuhelinnumerosi! ',//Sähköpostin muotovirhe
        verificationCode: 'vahvistuskoodi',
        verificationPlace: "Anna vahvistuskoodi",
        sendTitle: 'Lähetä vahvistuskoodi',
        sendText: 'Countdown',
        verificationCodeError: 'Anna vahvistuskoodi! ',
        passwordLabel: 'salasana',
        passPlaceholder: 'Anna salasana',
        confirmPassLabel: 'Vahvista salasana',
        confirmPlace: 'Vahvista salasana',
        passNullVerify: 'Anna salasanasi',//Anna salasanasi
        passErrorVerify: 'Salasana on alle 6 merkkiä pitkä! ',//Salasana on alle 6 merkkiä pitkä
        passCompareErrorVerify: 'Kahdesti annetut salasanat ovat epäjohdonmukaisia! ',
        passCompareErrorNull: 'Anna salasana uudelleen',
        registerHint: 'Onko sinulla jo tili? ',
        registerHintLogin: 'Kirjaudu',
        submitBtnTitle: "Rekisteröi",
        loginMessage: 'Vahvistuskoodi on virheellinen! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: "Myynti",
        payHintTitle1: 'Balance',
        payHintTitle2: 'PayPal',
        payHintTitle3: "Visa",
        conditionLabel: 'Condition:',
        conditionContent: "Brändiluokan tukemat maksutavat",
        frightLabel: "Logistiikka",
        frightContent: "Toimitus keskimäärin 1–3 päivässä",
        agreement: "Ostajan suojasopimus",
        specSelect: 'Valitse erittely',
        specLabel: 'Specification',
        specOptions: 'confirm',
        countLabel: 'Määrä',
        commentTitle: "Kommentoi",
        deliverTime: 'Arvioi toimitusaika ja -päivät',
        salesLabel: 'Myyntimäärä:',
        productCount: "Tuotteen määrä:",
        collectLabel: 'Keräyksen määrä:',
        averageLabel: 'Keskimääräinen päivien lukumäärä:',
        since: "Jäsenpäivä",
        shipper: 'pikavastaaja',
        responder: "Pikalähettäjä",
        reliable: "luotettava",
        btnContact: 'Ota yhteyttä',
        enter: 'closet',
        followBtn: 'kokoelma',
        guessLike: 'arvaa, pidät',
        buyNow: 'Osta nyt',
        dialogOverviewTitle: 'Yleiskatsaus',
        overConditionLabel: 'kunto',
        overConditionContent: 'uusi',
        overBrandLabel: "Brand",
        overCategoryLabel: 'Category',
        overPayMethodsLabel1: "Tuetut maksutavat",
        overPayMethodsLabel2: 'Tärkeää tietoa',
        overCategoryEnter: 'Vahvista',
        dialogFreightTitle: 'Logistiikkasopimus',
        dialogAgreementTitle: 'Ostajan suojasopimus',
        footerLeft: 'Lisää ostoskoriin',
        footerRight: "Osta nyt",
        activeLabel1: 'Ryhmäostos',
        activeLabel2: 'Second Sale',
        activeLabel3: 'Ennakkomyynti',
        flashStateNameE: 'End',
        flashStateNameI: "Ongoing",
        flashStateNameS: 'Ei aloitettu',
        flashHintSName: 'Aloita',
        flashHintEName: 'Ended',
        flashHintIName: 'Jäljellä oleva aika',
        preSaleStateTimeNO: 'Ei aloitettu',
        preSaleStateTimeE: 'End',
        preSaleStateTimeS: 'Ongoing',
        preSaleHintNoName: "Aloita",
        preSaleHintIEName: 'Ended',
        preSaleHintEName: 'End',
        groupHint: 'henkilö',
        reviewTitle: "Järjestelmän oletusarvostelu"
    },
    "appShops": {
        select1: 'Kattava',
        select2: 'Myyntimäärä',
        select3: 'Uusi tuote',
        select4: "Hinta korkeasta alhaiseen",
        select5: "Hinta alhaisesta korkeaan",
        select6: 'Rating',
        infoLabel1: 'Myyntimäärä',
        infoLabel2: "Tuotteen määrä",
        infoLabel3: 'Keräysmäärä',
        infoLabel4: 'Arvioi toimitusaika',
        memberLabel1: "Jäsenpäivä",
        memberLabel2: 'Pikavastaaja',
        memberLabel3: 'Pikatoimitus',
        memberLabel4: 'luotettava',
        commodity: 'tavara',
        classAll: 'kaikki',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Kommentit',
        reviewTitle: "Järjestelmän oletuskommentit",
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Version päivitys, vahvista? ',
        loadingTitle: 'Ladataan...',
        systemUpgrade: "Järjestelmän päivitys",
        upgradeImmediately: 'Vahvista',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Ladataan", // Ladataan
        finishedText: "Ei mitään", //Ei mitään
        loadingText: "Ladataan", // Ladataan
        nullText: "Ei vielä tietoja",
        loadingFinished: "Lataus valmis",
        listNullText: "Ei enää",
        pullIngText: 'Vedä alas päivittääksesi',
        looseText: 'Release to refresh',
        pullingSuccess: 'Pull-down loading onnistui',
        source: 'vahvista'
    },

};
export default obj;
