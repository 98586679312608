// 荷兰语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Thuis",
    },
    "searchLanguage": {
        searchBtn: 'Zoekopdracht',
        searchPlace: 'Zoekopdracht....'
    },
    'setDrawer': {
        label1: 'Meertalige instellingen',
        label2: 'Handelaar vestigde zich',
        label3: 'Aanmelding verkoper',
        label4: 'Klantenservice',
        label5: 'Android-applicatie downloaden',
        label6: 'ios-applicatie downloaden',
        optionsBtn: 'Annuleren'
    },
    "appService": {
        navTitle: 'Klantenservice',
        iconInformation: 'Selecteer communicatiesoftware voor advies',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: 'Handelaar heeft zich gevestigd',
        label1: 'Naam verkoper',
        label1Hint: 'Naam van verkoper of merk kan, eenmaal ingesteld, niet worden gewijzigd',
        visible1: 'Voer de naam van de verkoper in',

        label2: 'Postbus',
        label2Hint: 'Het wachtwoord waarmee de webwinkelier inlogt op het webwinkelportaal. ',
        visible2: 'Vul uw e-mailadres in',
        label3: 'E-mailverificatiecode',
        label3Hint: 'Code vereist om de identiteit van de verkoper te bevestigen en kwaadwillige activiteiten te voorkomen. Geef de juiste verificatiecode-informatie op',
        visible3: 'Voer de e-mailverificatiecode in',

        label4: 'Wachtwoord voor handelaar',
        label4Hint: 'Het wachtwoord waarmee de webwinkelier inlogt op het webwinkelportaal. ',
        visible4: 'Voer alstublieft het verkoperswachtwoord in',
        label5: 'uitnodigingscode',
        visible5: 'Vul de uitnodigingscode in',
        label5Hint: 'Voer de uitnodigingscode in van de handelaar die je heeft uitgenodigd. ',

        label6: 'Afbeelding opslaan',
        label6Hint: 'Winkelafbeelding van de verkoper',
        visible6: 'Voer de verificatiecode in',
        label7: 'Handelaarcertificaat',
        label7Hint: 'Certificatiedocumenten van de handelaar, zoals handelaarslicentie, belastingregistratiecertificaat, etc. Deze bestanden moeten afbeeldingsbestanden van hoge kwaliteit zijn. ',
        visible7: 'Upload alstublieft foto\'s van uw handelslicentie en relevante branchekwalificatiecertificaten (u kunt maximaal 2 foto\'s uploaden, elke foto heeft een maximale grootte van 5 MB en het beeldformaat ondersteunt Jpg en Png)',

        placeholderEnter: 'Voer in',
        placeholderSelect: 'Selecteer alstublieft',
        sendEmail: 'Verzenden',
        optionBtn1: 'Servicevoorwaarden',
        optionBtn2: 'Verkopersbescherming',
        optionBtn3: 'Verzenden ter beoordeling',
        uploadMessageError: 'Upload afbeelding mislukt',
        UploadLayoutError: 'Fout in afbeeldingsformaat',
        fileFail: 'Foto\'s uploaden en formatteren',
        popupTitle1: 'Servicevoorwaarden',
        popupTitle2: 'Verkopersbeschermingsovereenkomst'
    },
    'appSetLanguage': {
        navTitle: 'Meertalige instellingen',
        affirm: 'bevestigen',
    },
    'classifyDrawer': {
        navTitle: 'Categorie'
    },
    'appHome': {
        recommendTitle: 'Aanbevelen',
        viewAll: 'alles bekijken',
        usedTitle: 'Gebruikt',
        buyNow: 'Koop nu',
        shopTitle: 'Handelaar',
        shopOption: 'Kast',
        hotTitle: 'Heet',
        ranking: 'Verkooprangschikking',
        preSaleTitle: 'Voorverkoop',
        preSaleTabs1: 'Lopend',
        preSaleTabs2: 'Niet gestart',
        preSaleTabs3: 'Over',
        groupTitle: 'Groep',
        peopleGroupBuying: 'mensen deden mee',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'start',
        preSaleStateTimeE: 'einde',
        showNow: 'Ga nu shoppen'
    },
    "appShopsListIndex": {
        navTitle: 'Verkoperslijst',
        searchPlace: 'Zoeken...',
        favoriteTitle: 'Favoriete hoeveelheid &',
        shopBtn: 'Bekijk kast',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Categorie',
        viewAll: 'alles bekijken',
    },

    'appClassProductIndex': {
        navTitle: 'Product',
        select1: 'Uitgebreid',
        select2: 'Verkoophoeveelheid',
        select3: 'Nieuw product',
        select4: 'Prijs hoog naar laag',
        select5: 'Prijs laag naar hoog',
        select6: 'Beoordeling',
        paymentTitle: 'Betaling',

    },
    'appPreSale': {
        navTitle: 'Voorverkoop',
        tabs1: 'Lopend',
        tabs2: 'Niet gestart',
        tabs3: 'Over',
        selectAll: 'Alles',
        listLabel: 'Verzending',
        listLabe2: 'dag',
    },
    "appGroupBuying": {
        navTitle: 'Groep',
        selectAll: 'Alles',
        listLabel: 'Noodzaak',
        listLabe2: 'mensen',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Alle',
    },
    'appSalesRanking': {
        navTitle: 'Heet',
        payment: 'betaling',
        optionBtn1: 'Verzamelen',
        optionBtn2: 'Koop nu',
    },
    'appLogin': {
        navTitle: 'Inloggen',
        navTitleHint: 'Log nu in en begin met handelen met vrienden van over de hele wereld! ',
        singUp: 'Registreren',

        emailLabel: 'Mobiel telefoonnummer',
        emailPlaceholder: 'Vul uw mobiele telefoonnummer in',
        emailErrorText: 'Foutmelding! ',
        emailNullVerify: 'Voer uw mobiele telefoonnummer in!',//Voer uw e-mailadres in
        emailErrorVerify: 'Voer uw mobiele telefoonnummer in! ',//Fout in e-mailformaat

        passwordLabel: 'uw wachtwoord',
        passPlaceholder: 'Voer wachtwoord in',
        passNullVerify: 'Voer uw wachtwoord in', //Voer uw wachtwoord in
        passErrorVerify: 'Het wachtwoord is minder dan 6 tekens! ',//Het wachtwoord is minder dan 6 tekens

        placeholder: 'Voer in',

        emailRegistration: 'Registreer nu',
        forgetPassTitle: 'Wachtwoord vergeten? ',

        submitBtnTitle: 'Inloggen',
        loginMessage: 'Account of wachtwoord is onjuist! '
    },
    'appForgotPass': {
        navTitle: 'Wachtwoord vergeten',
        navTitleHint: 'Vul uw mobiele telefoonnummer in en ontvang de verificatiecode om een ​​nieuw wachtwoord in te stellen. ',
        emailLabel: 'Mobiel telefoonnummer',
        emailPlaceholder: 'Vul uw mobiele nummer in',
        emailErrorText: 'Foutmelding!',//Voer uw e-mailadres in
        emailNullVerify: 'Voer uw mobiele telefoonnummer in!',//Voer uw e-mailadres in
        emailErrorVerify: 'Voer uw mobiele telefoonnummer in!',//E-mailformaatfout
        verificationCode: 'verificatiecode',
        verificationPlace: 'verificatiecode',
        verificationCodeError: 'Voer de verificatiecode in! ',
        sendTitle: 'Verificatiecode verzenden',
        sendText: 'Opnieuw verifiëren',
        passwordLabel: 'Nieuw wachtwoord',
        passPlaceholder: 'Voer een nieuw wachtwoord in',
        confirmPassLabel: 'Bevestig nieuw wachtwoord',
        confirmPlace: 'Bevestig nieuw wachtwoord',
        passNullVerify: 'Voer uw nieuwe wachtwoord in', //Voer uw wachtwoord in
        passErrorVerify: 'Het wachtwoord is minder dan 6 tekens! ',//Het wachtwoord is minder dan 6 tekens
        passCompareErrorVerify: 'De tweemaal ingevoerde wachtwoorden zijn inconsistent! ',
        passCompareErrorNull: 'Voer het nieuwe wachtwoord opnieuw in',
        submitBtnTitle: 'Wachtwoord opnieuw instellen',
        loginMessage: 'De verificatiecode is onjuist! '
        // emailRegistration: 'Email registration',
        // forgetPassTitle: 'Forgot Password?',

    },
    'appRegister': {
        navTitle: 'Registreren',
        navTitleHint: 'Maak een account aan om toegang te krijgen tot alle functies! ',
        namePlace: 'Voer in',
        firstNameLabel: 'naam',
        firstNamePlace: 'Voer in',
        firstNameErrorVerify: 'Voer uw naam in',
        lastNameLabel: 'achternaam',
        lastNameErrorVerify: 'Voer uw achternaam in',
        nickNameLabel: 'bijnaam',
        nickNameErrorVerify: 'Voer uw bijnaam in',
        emailLabel: 'Mobiel telefoonnummer',
        emailPlaceholder: 'Vul uw mobiele nummer in',
        emailErrorText: 'Foutmelding!',//Voer uw e-mailadres in
        emailNullVerify: 'Voer uw mobiele telefoonnummer in!',//Voer uw e-mailadres in
        emailErrorVerify: 'Voer uw mobiele telefoonnummer in! ',//Fout in e-mailformaat
        verificationCode: 'verificatiecode',
        verificationPlace: 'Voer de verificatiecode in',
        sendTitle: 'Verificatiecode verzenden',
        sendText: 'Aftellen',
        verificationCodeError: 'Voer de verificatiecode in! ',
        passwordLabel: 'wachtwoord',
        passPlaceholder: 'Voer wachtwoord in',
        confirmPassLabel: 'Wachtwoord bevestigen',
        confirmPlace: 'Wachtwoord bevestigen',
        passNullVerify: 'Voer uw wachtwoord in',//Voer uw wachtwoord in
        passErrorVerify: 'Het wachtwoord is minder dan 6 tekens! ',//Het wachtwoord is minder dan 6 tekens
        passCompareErrorVerify: 'De tweemaal ingevoerde wachtwoorden zijn inconsistent! ',
        passCompareErrorNull: 'Voer het wachtwoord opnieuw in',
        registerHint: 'Heb je al een account? ',
        registerHintLogin: 'Inloggen',
        submitBtnTitle: 'Registreren',
        loginMessage: 'De verificatiecode is onjuist! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Verkoop',
        payHintTitle1: 'Saldo',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visum',
        conditionLabel: 'Voorwaarde:',
        conditionContent: 'Merkcategorie-ondersteunde betaalmethoden',
        frightLabel: 'Logistiek',
        frightContent: 'Levering gemiddeld binnen 1~3 dagen',
        agreement: 'Kopersbeschermingsovereenkomst',
        specSelect: 'Selecteer een specificatie',
        specLabel: 'Specificatie',
        specOptions: 'bevestigen',
        countLabel: 'Aantal',
        commentTitle: 'Commentaar',
        deliverTime: 'Evalueer levertijd en dagen',
        salesLabel: 'Verkoophoeveelheid:',
        productCount: "Producthoeveelheid:",
        collectLabel: 'Verzamelingsaantal:',
        averageLabel: 'Gemiddeld aantal dagen:',
        since: 'Ledendag',
        shipper: 'quick responder',
        responder: 'Quick Shipper',
        reliable: 'betrouwbaar',
        btnContact: 'Contact',
        enter: 'kast',
        followBtn: 'Verzameling',
        guessLike: 'denk dat je het leuk vindt',
        buyNow: 'Koop nu',
        dialogOverviewTitle: 'Overzicht',
        overConditionLabel: 'Conditie',
        overConditionContent: 'nieuw',
        overBrandLabel: 'Merk',
        overCategoryLabel: 'Categorie',
        overPayMethodsLabel1: 'Ondersteunde betaalmethoden',
        overPayMethodsLabel2: 'Belangrijke informatie',
        overCategoryEnter: 'Bevestigen',
        dialogFreightTitle: 'Logistieke Overeenkomst',
        dialogAgreementTitle: 'Kopersbeschermingsovereenkomst',
        footerLeft: 'Toevoegen aan winkelwagen',
        footerRight: 'Koop nu',
        activeLabel1: 'Groepsaankoop',
        activeLabel2: 'Tweede verkoop',
        activeLabel3: 'Voorverkoop',
        flashStateNameE: 'Einde',
        flashStateNameI: 'Lopend',
        flashStateNameS: 'Niet gestart',
        flashHintSName: 'Start',
        flashHintEName: 'Beëindigd',
        flashHintIName: 'Resterende tijd',
        preSaleStateTimeNO: 'Niet gestart',
        preSaleStateTimeE: 'Einde',
        preSaleStateTimeS: 'Lopend',
        preSaleHintNoName: 'Start',
        preSaleHintIEName: 'Beëindigd',
        preSaleHintEName: 'Einde',
        groupHint: 'persoon',
        reviewTitle: 'Systeemstandaardbeoordeling'
    },
    "appShops": {
        select1: 'Uitgebreid',
        select2: 'Verkoophoeveelheid',
        select3: 'Nieuw product',
        select4: 'Prijs hoog naar laag',
        select5: 'Prijs laag naar hoog',
        select6: 'Beoordeling',
        infoLabel1: 'Verkoophoeveelheid',
        infoLabel2: 'Producthoeveelheid',
        infoLabel3: 'Verzamelhoeveelheid',
        infoLabel4: 'Evalueer de levertijd',
        memberLabel1: 'Ledendag',
        memberLabel2: 'Snelle respons',
        memberLabel3: 'Snelle verzender',
        memberLabel4: 'betrouwbaar',
        commodity: 'waar',
        classAll: 'alle',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Opmerkingen',
        reviewTitle: 'Systeemstandaardopmerkingen',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Versie-upgrade, bevestig alstublieft? ',
        loadingTitle: 'Downloaden...',
        systemUpgrade: 'Systeemupgrade',
        upgradeImmediately: 'Bevestigen',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Laden", // Laden
        finishedText: "Geen", //Geen
        loadingText: "Laden", // Laden
        nullText: 'Nog geen gegevens',
        loadingFinished: "Laden voltooid",
        listNullText: 'Niet meer',
        pullIngText: 'Naar beneden trekken om te vernieuwen',
        looseText: 'Laat los om te vernieuwen',
        pullingSuccess: 'Pull-down laden succesvol',
        source: 'bevestigen'
    },

};
export default obj;
