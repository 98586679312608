// 阿拉伯语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "بيت",
    },
    "searchLanguage": {
        searchBtn: "بحث",
        searchPlace: 'بحث....'
    },
    'setDrawer': {
        label1: "إعدادات متعددة اللغات",
        label2: "استقر التاجر",
        label3: "تسجيل دخول التاجر",
        label4: "خدمة العملاء",
        label5: "تنزيل تطبيق Android",
        label6: "تنزيل تطبيق ios",
        optionsBtn: 'إلغاء'
    },
    "appService": {
        navTitle: "خدمة العملاء",
        iconInformation: "يرجى تحديد برنامج الاتصال للتشاور",
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: "استقر التاجر",
        label1: "اسم التاجر",
        label1Hint: "لا يمكن تعديل اسم التاجر أو اسم العلامة التجارية, بمجرد تعيينه",
        visible1: "الرجاء إدخال اسم التاجر",

        label2: "صندوق البريد",
        label2Hint: "كلمة المرور التي يستخدمها التاجر لتسجيل الدخول إلى بوابة التاجر. ",
        visible2: "الرجاء إدخال بريدك الإلكتروني",
        label3: "رمز التحقق من البريد الإلكتروني",
        label3Hint: "الرمز مطلوب لتأكيد هوية التاجر ومنع الأنشطة الضارة. يرجى تقديم معلومات رمز التحقق الصحيحة",
        visible3: "الرجاء إدخال رمز التحقق من البريد الإلكتروني",

        label4: "كلمة مرور التاجر",
        label4Hint: "كلمة المرور التي يستخدمها التاجر لتسجيل الدخول إلى بوابة التاجر. ",
        visible4: "الرجاء إدخال كلمة مرور التاجر",
        label5: "رمز الدعوة",
        visible5: "الرجاء إدخال رمز الدعوة",
        label5Hint: "أدخل رمز الدعوة الخاص بالتاجر الذي دعاك. ",

        label6: "صورة المتجر",
        label6Hint: "صورة متجر التاجر",
        visible6: "الرجاء إدخال رمز التحقق",
        label7: "شهادة التاجر",
        label7Hint: "وثائق شهادة التاجر, مثل رخصة التاجر, وشهادة التسجيل الضريبي, وما إلى ذلك. يجب أن تكون هذه الملفات عبارة عن ملفات صور عالية الجودة. ",
        visible7: "يُرجى تحميل صور رخصة التاجر الخاصة بك وشهادات التأهيل الصناعية ذات الصلة (يمكنك تحميل ما يصل إلى صورتين, يبلغ الحد الأقصى لحجم كل صورة 5 ميجابايت, ويدعم تنسيق الصورة Jpg وPng)",

        placeholderEnter: "الرجاء الدخول",
        placeholderSelect: "يرجى التحديد",
        sendEmail: "إرسال",
        optionBtn1: "شروط الخدمة",
        optionBtn2: "حماية البائع",
        optionBtn3: "إرسال للمراجعة",
        uploadMessageError: "فشل تحميل الصورة",
        UploadLayoutError: "خطأ في تنسيق الصورة",
        fileFail: "يرجى تحميل الصور وتنسيقها",
        popupTitle1: "شروط الخدمة",
        popupTitle2: "اتفاقية حماية البائع"
    },
    'appSetLanguage': {
        navTitle: 'إعدادات متعددة اللغات',
        affirm: 'يتأكد',
    },
    'classifyDrawer': {
        navTitle: 'فئة'
    },
    'appHome': {
        recommendTitle: 'يوصي',
        viewAll: 'عرض الكل',
        usedTitle: 'مستخدم',
        buyNow: 'اشتري الآن',
        shopTitle: 'تاجر',
        shopOption: 'خزانة',
        hotTitle: 'حار',
        ranking: 'تصنيف المبيعات',
        preSaleTitle: 'ما قبل البيع',
        preSaleTabs1: 'جاري التنفيذ',
        preSaleTabs2: 'لم يبدأ',
        preSaleTabs3: 'زيادة',
        groupTitle: 'مجموعة',
        peopleGroupBuying: 'شارك الناس',
        flashSaleTitle: 'فلاش',
        preSaleStateTimeS: 'يبدأ',
        preSaleStateTimeE: 'نهاية',
        showNow: 'اذهب للتسوق الآن',
    },
    "appShopsListIndex": {
        navTitle: 'قائمة التاجر',
        searchPlace: 'يبحث...',
        favoriteTitle: 'الكمية المفضلة&',
        shopBtn: 'عرض الخزانة',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'فئة',
        viewAll: 'عرض الكل',
    },

    'appClassProductIndex': {
        navTitle: 'منتج',
        select1: 'شامل',
        select2: 'كمية المبيعات',
        select3: 'منتج جديد',
        select4: 'السعر الاعلى الى الادنى',
        select5: 'السعر من الارخص للاعلى',
        select6: 'تقييم',
        paymentTitle: 'قسط',

    },
    'appPreSale': {
        navTitle: 'ما قبل البيع',
        tabs1: 'جاري التنفيذ',
        tabs2: 'لم يبدأ',
        tabs3: 'زيادة',
        selectAll: 'الجميع',
        listLabel: 'شحن',
        listLabe2: 'يوم',
    },
    "appGroupBuying": {
        navTitle: 'مجموعة',
        selectAll: 'الجميع',
        listLabel: 'يحتاج',
        listLabe2: 'الناس',
    },
    "appFlashSale": {
        navTitle: 'فلاش',
        selectAll: 'الجميع',
    },
    'appSalesRanking': {
        navTitle: 'حار',
        payment: 'قسط',
        optionBtn1: 'يجمع',
        optionBtn2: 'اشتري الآن',
    },
    'appLogin': {
        navTitle: 'تسجيل الدخول',
        navTitleHint: 'قم بتسجيل الدخول الآن وابدأ التداول مع الأصدقاء من جميع أنحاء العالم!',
        singUp: 'يسجل',

        emailLabel: 'رقم الهاتف الجوال',
        emailPlaceholder: 'الرجاء إدخال رقم الهاتف المحمول الخاص بك',
        emailErrorText: 'رسالة خطأ! ',
        emailNullVerify: 'الرجاء إدخال رقم هاتفك المحمول!',//Please enter your email address
        emailErrorVerify: 'الرجاء إدخال رقم هاتفك المحمول! ',//Email format error

        passwordLabel: 'كلمة السر خاصتك',
        passPlaceholder: 'الرجاء إدخال كلمة المرور',
        passNullVerify: 'من فضلك أدخل رقمك السري', //Please enter your password
        passErrorVerify: 'كلمة المرور أقل من 6 أحرف!',//The password is less than 6 characters

        placeholder: 'تفضل',

        emailRegistration: 'سجل الان',
        forgetPassTitle: 'نسيت كلمة السر؟ ',

        submitBtnTitle: 'تسجيل الدخول',
        loginMessage: 'الحساب أو كلمة المرور غير صحيحة!'
    },
    'appForgotPass': {
        navTitle: "نسيت كلمة المرور",
        navTitleHint: "يرجى إدخال رقم هاتفك المحمول واستلام رمز التحقق لتعيين كلمة مرور جديدة. ",
        emailLabel: "رقم الهاتف المحمول",
        emailPlaceholder: "الرجاء إدخال رقم هاتفك المحمول",
        emailErrorText: "رسالة خطأ!", // الرجاء إدخال عنوان بريدك الإلكتروني
        emailNullVerify: 'الرجاء إدخال رقم هاتفك المحمول!',//الرجاء إدخال عنوان بريدك الإلكتروني
        emailErrorVerify: 'الرجاء إدخال رقم هاتفك المحمول!',//خطأ في تنسيق البريد الإلكتروني
        verificationCode: "رمز التحقق",
        verificationPlace: "رمز التحقق",
        verificationCodeError: "الرجاء إدخال رمز التحقق! ",
        sendTitle: "إرسال رمز التحقق",
        sendText: "إعادة التحقق",
        passwordLabel: "كلمة المرور الجديدة",
        passPlaceholder: "الرجاء إدخال كلمة مرور جديدة",
        confirmPassLabel: "تأكيد كلمة المرور الجديدة",
        confirmPlace: "تأكيد كلمة المرور الجديدة",
        passNullVerify: "أدخل كلمة المرور الجديدة", // الرجاء إدخال كلمة المرور الخاصة بك
        passErrorVerify: 'كلمة المرور أقل من 6 أحرف! ',//كلمة المرور أقل من 6 أحرف
        passCompareErrorVerify: 'كلمات المرور التي تم إدخالها مرتين غير متناسقة!',
        passCompareErrorNull: "الرجاء إدخال كلمة المرور الجديدة مرة أخرى",
        submitBtnTitle: "إعادة تعيين كلمة المرور",
        loginMessage: 'رمز التحقق غير صحيح!'
        // emailRegistration: 'تسجيل البريد الإلكتروني',
        // forgetPassTitle: 'هل نسيت كلمة السر؟',

    },
    'appRegister': {
        navTitle: 'التسجيل',
        navTitleHint: "أنشئ حسابًا للوصول إلى جميع الميزات!",
        namePlace: "الرجاء الدخول",
        firstNameLabel: 'الاسم',
        firstNamePlace: "الرجاء الدخول",
        firstNameErrorVerify: "الرجاء إدخال اسمك",
        lastNameLabel: "الاسم الأخير",
        lastNameErrorVerify: "الرجاء إدخال اسمك الأخير",
        nickNameLabel: "اللقب",
        nickNameErrorVerify: "الرجاء إدخال لقبك",
        emailLabel: "رقم الهاتف المحمول",
        emailPlaceholder: "الرجاء إدخال رقم هاتفك المحمول",
        emailErrorText: "رسالة خطأ!", // الرجاء إدخال عنوان بريدك الإلكتروني
        emailNullVerify: 'الرجاء إدخال رقم هاتفك المحمول!',//الرجاء إدخال عنوان بريدك الإلكتروني
        emailErrorVerify: 'الرجاء إدخال رقم هاتفك المحمول! ',// خطأ في تنسيق البريد الإلكتروني
        verificationCode: "رمز التحقق",
        verificationPlace: "الرجاء إدخال رمز التحقق",
        sendTitle: "إرسال رمز التحقق",
        sendText: "العد التنازلي",
        verificationCodeError: "الرجاء إدخال رمز التحقق!",
        passwordLabel: "كلمة المرور",
        passPlaceholder: "الرجاء إدخال كلمة المرور",
        confirmPassLabel: "تأكيد كلمة المرور",
        confirmPlace: "تأكيد كلمة المرور",
        passNullVerify: "أدخل كلمة المرور الخاصة بك",// الرجاء إدخال كلمة المرور الخاصة بك
        passErrorVerify: 'كلمة المرور أقل من 6 أحرف! ',//كلمة المرور أقل من 6 أحرف
        passCompareErrorVerify: 'كلمات المرور التي تم إدخالها مرتين غير متناسقة!',
        passCompareErrorNull: "الرجاء إدخال كلمة المرور مرة أخرى",
        registerHint: "هل لديك حساب بالفعل؟ ",
        registerHintLogin: "تسجيل الدخول",
        submitBtnTitle: "التسجيل",
        loginMessage: 'رمز التحقق غير صحيح! '

        // gender: 'جنس',
        // gender1: 'ولد',
        // gender2: 'بنت',
        // gender3: 'السرية',
        // genderError: 'الرجاء إدخال جنسك',
    },
    'appGoodsDetail': {
        salesTitle: "المبيعات",
        payHintTitle1: "الرصيد",
        payHintTitle2: "PayPal",
        payHintTitle3: "التأشيرة",
        conditionLabel: 'الحالة:',
        conditionContent: "طرق الدفع المدعومة بفئة العلامة التجارية",
        frightLabel: "اللوجستيات",
        frightContent: "التسليم خلال 1 إلى 3 أيام في المتوسط",
        agreement: "اتفاقية حماية المشتري",
        specSelect: "الرجاء تحديد المواصفات",
        specLabel: "المواصفات",
        specOptions: "تأكيد",
        countLabel: "الكمية",
        commentTitle: "تعليق",
        deliverTime: "تقييم وقت التسليم وأيامه",
        salesLabel: 'كمية المبيعات:',
        productCount: "كمية المنتج:",
        collectLabel: 'كمية المجموعة:',
        averageLabel: 'متوسط ​​عدد الأيام:',
        since: "يوم العضو",
        shipper: "الاستجابة السريعة",
        responder: "الشاحن السريع",
        reliable: "موثوق",
        btnContact: "اتصال",
        enter: "خزانة",
        followBtn: "المجموعة",
        guessLike: "أعتقد أنك تريد",
        buyNow: "اشتري الآن",
        dialogOverviewTitle: "نظرة عامة",
        overConditionLabel: "الحالة",
        overConditionContent: "جديد",
        overBrandLabel: "العلامة التجارية",
        overCategoryLabel: "الفئة",
        overPayMethodsLabel1: "طرق الدفع المدعومة",
        overPayMethodsLabel2: "معلومات مهمة",
        overCategoryEnter: "تأكيد",
        dialogFreightTitle: "اتفاقية الخدمات اللوجستية",
        dialogAgreementTitle: "اتفاقية حماية المشتري",
        footerLeft: 'أضف إلى سلة التسوق',
        footerRight: "اشتر الآن",
        activeLabel1: "شراء جماعي",
        activeLabel2: "البيع الثاني",
        activeLabel3: "البيع المسبق",
        flashStateNameE: 'النهاية',
        flashStateNameI: "مستمر",
        flashStateNameS: "لم يبدأ",
        flashHintSName: "ابدأ",
        flashHintEName: "انتهى",
        flashHintIName: "الوقت المتبقي",
        preSaleStateTimeNO: "لم يبدأ",
        preSaleStateTimeE: 'النهاية',
        preSaleStateTimeS: "مستمر",
        preSaleHintNoName: 'ابدأ',
        preSaleHintIEName: 'انتهى',
        preSaleHintEName: 'نهاية',
        groupHint: "شخص",
        reviewTitle: "المراجعة الافتراضية للنظام"
    },
    "appShops": {
        select1: "شامل",
        select2: "كمية المبيعات",
        select3: "منتج جديد",
        select4: "السعر من الأعلى إلى الأدنى",
        select5: "السعر من الأقل إلى الأعلى",
        select6: "التقييم",
        infoLabel1: "كمية المبيعات",
        infoLabel2: "كمية المنتج",
        infoLabel3: "كمية المجموعة",
        infoLabel4: "تقييم وقت التسليم",
        memberLabel1: "يوم العضو",
        memberLabel2: "المستجيب السريع",
        memberLabel3: "الشاحن السريع",
        memberLabel4: "موثوق",
        commodity: "سلعة",
        classAll: 'الكل',

        // timeDay: 'تقييم وقت التسليم والأيام',
        // productQuantity: 'كمية المنتج',
        // shippedQuantity: 'الكمية المشحونة',
        // collections: "عدد المجموعات",
    },
    // 'appSearchHistory': {
    //   navTitle: 'يبحث',
    //   searchPlace: 'يبحث...',
    //   recommendTitle: 'يوصي'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'تعليقات',
        reviewTitle: 'التعليقات الافتراضية للنظام',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: "ترقية الإصدار, يرجى التأكيد؟",
        loadingTitle: "جارٍ التنزيل...",
        systemUpgrade: "ترقية النظام",
        upgradeImmediately: "تأكيد",
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "جارٍ التحميل", // جاري التحميل
        finishedText: "لا شيء", //لا شيء
        loadingText: "جارٍ التحميل", // جارٍ التحميل
        nullText: "لا توجد بيانات بعد",
        loadingFinished: "اكتمل التحميل",
        listNullText: "لا أكثر",
        pullIngText: "اسحب للأسفل للتحديث",
        looseText: "الإصدار للتحديث",
        pullingSuccess: "تم التحميل المنسدل بنجاح",
        source: "تأكيد"
    },

};
export default obj;
