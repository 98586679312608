<template>
  <div class="service-app-warper">
    <van-popup
      v-model="popupVisible"
      @close="handlerClose"
      class="service-popup-dialog"
    >
      <div class="popup-title">{{ language.navTitle }}</div>
      <div class="popup-body">
        <div class="popup-hint">
          <svg-icon icon-class="app-info-service"></svg-icon>
          {{ language.iconInformation }}
        </div>
        <div class="footer_imagesList">
          <div
            class="image_item"
            @click="onChangeService('whatsApp')"
            v-if="serviceData.whatsapp"
          >
            <img :src="getImgUrL(1)" />
            <p>{{ language.whatsApp }}</p>
          </div>
          <div
            class="image_item"
            @click="onChangeService('telegram')"
            v-if="serviceData.telegram"
          >
            <img :src="getImgUrL(2)" />
            <p>{{ language.telegram }}</p>
          </div>
          <div
            class="image_item"
            @click="onChangeService('line')"
            v-if="serviceData.line"
          >
            <img :src="getImgUrL(3)" />
            <p>{{ language.line }}</p>
          </div>
          <div
              class="image_item"
              @click="onChangeService('kakao')"
              v-if="serviceData.kakao"
          >
            <img :src="getImgUrL(4)" />
            <p>{{ language.kakao }}</p>
          </div>
          <div
              class="image_item"
              @click="onChangeService('signal')"
              v-if="serviceData.signal"
          >
            <img :src="getImgUrL(5)" />
            <p>{{ language.signal }}</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import imageUrlApi from "@/utils/projectConfig";
import serviceApi from "@/api/h5Api/service";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    serviceObj: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      popupVisible: false,
      imageUrlPath: imageUrlApi.url,
      language: this.$language("appService"),
      serviceData: {},
    };
  },
  created() {
    this.popupVisible = this.visible;
    this.serviceData = this.serviceObj;
  },
  methods: {
    handlerClose() {
      this.popupVisible = false;
      this.$emit("handlerCloseService");
    },
    onChangeService(key) {
      if (key == "whatsApp") {
        this.$globalCommon.$openUrl(this.serviceData.whatsapp);
      }
      if (key == "telegram") {
        this.$globalCommon.$openUrl(this.serviceData.telegram);
      }
      if (key == "line") {
        this.$globalCommon.$openUrl(this.serviceData.line);
      }
      if (key == "kakao" || key == "signal") {
        this.$globalCommon.$openUrl(this.serviceData[key]);
      }
    },
    // async init() {
    //   const { code, data, message } = await serviceApi.getService({
    //     code: localStorage.getItem("code") ?? "",
    //   });
    //   if (code == 200) {
    //     this.serviceData = data;
    //   }
    // },
    getImgUrL(state) {
      return require(`../../../assets/h5/service${state}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.service-popup-dialog {
  width: 620px;
  max-height: 75%;
  padding: 0 30px;
  border-radius: 18px;
  .popup-title {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 1.5;
    color: #030303;
    font-weight: bold;
  }
  .popup-body {
    padding: 30px;
    .popup-hint {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 15px;
      display: flex;
      align-items: flex-start;
      svg {
        margin-right: 8px;
        width: 36px !important;
        height: 36px !important;
      }
    }
    .footer_imagesList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 30px;
      padding-bottom: 30px;
      .image_item {
        width: 31%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          width: 78px;
          height: 78px;
        }
        p {
          margin-top: 15px;
          font-size: 28px;
          color: #6d3580;
          line-height: 1.5;
          color: #6d3580;
        }
      }
    }
  }
}
</style>
