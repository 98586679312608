// 葡萄牙语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Lar",
    },
    "searchLanguage": {
        searchBtn: 'Procurar',
        searchPlace: 'Procurar....'
    },
    'setDrawer': {
        label1: 'Configurações multilíngues',
        label2: 'Comerciante estabelecido',
        label3: 'Login do comerciante',
        label4: 'Atendimento ao Cliente',
        label5: 'Baixar aplicativo Android',
        label6: 'Baixar aplicativo iOS',
        optionsBtn: 'Cancelar'
    },
    "appService": {
        navTitle: 'Atendimento ao Cliente',
        iconInformation: 'Selecione o software de comunicação para consulta',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: 'Comerciante instalado',
        label1: 'Nome do comerciante',
        label1Hint: 'Nome do comerciante ou marca, uma vez definido, não pode ser modificado',
        visible1: 'Por favor, insira o nome do comerciante',

        label2: 'Caixa de correio',
        label2Hint: 'A senha usada pelo comerciante para fazer login no portal do comerciante. ',
        visible2: 'Por favor, insira seu e-mail',
        label3: 'Código de verificação de e-mail',
        label3Hint: 'Código necessário para confirmar a identidade do comerciante e evitar atividades maliciosas. Forneça informações corretas do código de verificação',
        visible3: 'Por favor, insira o código de verificação de e-mail',

        label4: 'Senha do comerciante',
        label4Hint: 'A senha usada pelo comerciante para fazer login no portal do comerciante. ',
        visible4: 'Por favor, insira a senha do comerciante',
        label5: 'código de convite',
        visible5: 'Por favor, insira o código do convite',
        label5Hint: 'Insira o código de convite do comerciante que convidou você. ',

        label6: 'Armazenar imagem',
        label6Hint: 'Imagem da loja do comerciante',
        visible6: 'Por favor, insira o código de verificação',
        label7: 'Certificado de Comerciante',
        label7Hint: 'Documentos de certificação do comerciante, como licença de comerciante, certificado de registro fiscal, etc. Esses arquivos devem ser arquivos de imagem de alta qualidade. ',
        visible7: 'Faça upload de fotos de sua licença de comerciante e certificados de qualificação do setor relevantes (você pode fazer upload de até 2 fotos, cada imagem tem tamanho máximo de 5 MB e o formato de imagem suporta Jpg e Png)',

        placeholderEnter: 'Por favor, insira',
        placeholderSelect: 'Por favor selecione',
        sendEmail: 'Enviar',
        optionBtn1: 'Termos de Serviço',
        optionBtn2: 'Proteção ao Vendedor',
        optionBtn3: 'Enviar para revisão',
        uploadMessageError: 'Falha no upload da imagem',
        UploadLayoutError: 'Erro no formato da imagem',
        fileFail: 'Por favor, carregue e formate as imagens',
        popupTitle1: 'Termos de Serviço',
        popupTitle2: 'Contrato de Proteção ao Vendedor'
    },
    'appSetLanguage': {
        navTitle: 'Configurações multilíngues',
        affirm: 'confirmar',
    },
    'classifyDrawer': {
        navTitle: 'Categoria'
    },
    'appHome': {
        recommendTitle: 'Recomendar',
        viewAll: 'ver tudo',
        usedTitle: 'Usado',
        buyNow: 'Compre agora',
        shopTitle: 'Comerciante',
        shopOption: 'Armário',
        hotTitle: 'Quente',
        ranking: 'Classificação de vendas',
        preSaleTitle: 'Pré-venda',
        preSaleTabs1: 'Em andamento',
        preSaleTabs2: 'Não iniciado',
        preSaleTabs3: 'Acima',
        groupTitle: 'Grupo',
        peopleGroupBuying: 'pessoas participaram',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'início',
        preSaleStateTimeE: 'fim',
        showNow: 'Vá às compras agora'
    },
    "appShopsListIndex": {
        navTitle: 'Lista de Comerciantes',
        searchPlace: 'Pesquisar...',
        favoriteTitle: 'Quantidade favorita &',
        shopBtn: 'Ver Armário',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Categoria',
        viewAll: 'ver tudo',
    },

    'appClassProductIndex': {
        navTitle: 'Produto',
        select1: 'Abrangente',
        select2: 'Quantidade de vendas',
        select3: 'Novo produto',
        select4: 'Preço alto para baixo',
        select5: 'Preço baixo a alto',
        select6: 'Classificação',
        paymentTitle: 'Pagamento',

    },
    'appPreSale': {
        navTitle: 'Pré-venda',
        tabs1: 'Em andamento',
        tabs2: 'Não iniciado',
        tabs3: 'Acima',
        selectAll: 'Todos',
        listLabel: 'Envio',
        listLabe2: 'dia',
    },
    "appGroupBuying": {
        navTitle: 'Grupo',
        selectAll: 'Todos',
        listLabel: 'Necessidade',
        listLabe2: 'pessoas',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Todos',
    },
    'appSalesRanking': {
        navTitle: 'Quente',
        payment: 'pagamento',
        optionBtn1: 'Coletar',
        optionBtn2: 'Compre agora',
    },
    'appLogin': {
        navTitle: 'Login',
        navTitleHint: 'Faça login agora e comece a negociar com amigos de todo o mundo! ',
        singUp: 'Registrar',

        emailLabel: 'Número de celular',
        emailPlaceholder: 'Por favor, insira o número do seu celular',
        emailErrorText: 'Mensagem de erro! ',
        emailNullVerify: 'Por favor, digite seu número de celular!', //Por favor, digite seu endereço de e-mail
        emailErrorVerify: 'Por favor, insira o número do seu celular! ',//Erro no formato do e-mail

        passwordLabel: 'sua senha',
        passPlaceholder: 'Por favor, digite a senha',
        passNullVerify: 'Por favor, digite sua senha', //Por favor, digite sua senha
        passErrorVerify: 'A senha tem menos de 6 caracteres! ',//A senha tem menos de 6 caracteres

        placeholder: 'Por favor, insira',

        emailRegistration: 'Registre-se agora',
        forgetPassTitle: 'Esqueceu sua senha? ',

        submitBtnTitle: 'Login',
        loginMessage: 'Conta ou senha incorreta! '
    },
    'appForgotPass': {
        navTitle: 'Esqueci a senha',
        navTitleHint: 'Por favor, preencha o número do seu celular e receba o código de verificação para definir uma nova senha. ',
        emailLabel: 'Número de celular',
        emailPlaceholder: 'Por favor, insira seu número de celular',
        emailErrorText: 'Mensagem de erro!', //Por favor, insira seu endereço de e-mail
        emailNullVerify: 'Por favor, digite seu número de celular!', //Por favor, digite seu endereço de e-mail
        emailErrorVerify: 'Por favor, insira o número do seu celular!', // Erro no formato do email
        verificationCode: 'código de verificação',
        verificationPlace: 'código de verificação',
        verificationCodeError: 'Por favor, insira o código de verificação! ',
        sendTitle: 'Enviar código de verificação',
        sendText: 'Reverificar',
        passwordLabel: 'Nova senha',
        passPlaceholder: 'Por favor, insira uma nova senha',
        confirmPassLabel: 'Confirmar nova senha',
        confirmPlace: 'Confirmar nova senha',
        passNullVerify: 'Digite sua nova senha', //Por favor, digite sua senha
        passErrorVerify: 'A senha tem menos de 6 caracteres! ',//A senha tem menos de 6 caracteres
        passCompareErrorVerify: 'As senhas digitadas duas vezes são inconsistentes! ',
        passCompareErrorNull: 'Por favor, digite a nova senha novamente',
        submitBtnTitle: 'Redefinir senha',
        loginMessage: 'O código de verificação está incorreto! '
        // emailRegistration: 'Registro de e-mail',
        //esqueçaPassTitle: 'Esqueceu a senha?',

    },
    'appRegister': {
        navTitle: 'Registrar',
        navTitleHint: 'Crie uma conta para acessar todos os recursos! ',
        namePlace: 'Por favor, insira',
        firstNameLabel: 'nome',
        firstNamePlace: 'Por favor, insira',
        firstNameErrorVerify: 'Por favor, digite seu nome',
        lastNameLabel: 'sobrenome',
        lastNameErrorVerify: 'Por favor, digite seu sobrenome',
        nickNameLabel: 'apelido',
        nickNameErrorVerify: 'Por favor, digite seu apelido',
        emailLabel: 'Número de celular',
        emailPlaceholder: 'Por favor, insira seu número de celular',
        emailErrorText: 'Mensagem de erro!', //Por favor, insira seu endereço de e-mail
        emailNullVerify: 'Por favor, digite seu número de celular!', //Por favor, digite seu endereço de e-mail
        emailErrorVerify: 'Por favor, insira o número do seu celular! ',//Erro no formato do e-mail
        verificationCode: 'código de verificação',
        verificationPlace: 'Por favor, insira o código de verificação',
        sendTitle: 'Enviar código de verificação',
        sendText: 'Contagem regressiva',
        verificationCodeError: 'Por favor, insira o código de verificação! ',
        passwordLabel: 'senha',
        passPlaceholder: 'Por favor, digite a senha',
        confirmPassLabel: 'Confirmar senha',
        confirmPlace: 'Confirmar senha',
        passNullVerify: 'Digite sua senha', //Por favor, digite sua senha
        passErrorVerify: 'A senha tem menos de 6 caracteres! ',//A senha tem menos de 6 caracteres
        passCompareErrorVerify: 'As senhas digitadas duas vezes são inconsistentes! ',
        passCompareErrorNull: 'Por favor, digite a senha novamente',
        registerHint: 'Já tem uma conta? ',
        registerHintLogin: 'Login',
        submitBtnTitle: 'Registrar',
        loginMessage: 'O código de verificação está incorreto! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Vendas',
        payHintTitle1: 'Saldo',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visto',
        conditionLabel: 'Condição:',
        conditionContent: 'Métodos de pagamento suportados por categoria de marca',
        frightLabel: 'Logística',
        frightContent: 'Entrega dentro de 1 a 3 dias em média',
        agreement: 'Acordo de Proteção ao Comprador',
        specSelect: 'Por favor, selecione uma especificação',
        specLabel: 'Especificação',
        specOptions: 'confirmar',
        countLabel: 'Quantidade',
        commentTitle: 'Comentário',
        deliverTime: 'Avaliar prazo e dias de entrega',
        salesLabel: 'Quantidade de vendas:',
        productCount: "Quantidade do produto:",
        collectLabel: 'Quantidade coletada:',
        averageLabel: 'Número médio de dias:',
        since: 'Dia do Membro',
        shipper: 'resposta rápida',
        responder: 'Remetente Rápido',
        reliable: 'confiável',
        btnContact: 'Contato',
        enter: 'armário',
        followBtn: 'Coleção',
        guessLike: 'acho que você gosta',
        buyNow: 'Compre agora',
        dialogOverviewTitle: 'Visão geral',
        overConditionLabel: 'Condição',
        overConditionContent: 'novo',
        overBrandLabel: 'Marca',
        overCategoryLabel: 'Categoria',
        overPayMethodsLabel1: 'Métodos de pagamento suportados',
        overPayMethodsLabel2: 'Informações importantes',
        overCategoryEnter: 'Confirmar',
        dialogFreightTitle: 'Acordo de Logística',
        dialogAgreementTitle: 'Acordo de Proteção ao Comprador',
        footerLeft: 'Adicionar ao carrinho',
        footerRight: 'Compre agora',
        activeLabel1: 'Compra em grupo',
        activeLabel2: 'Segunda venda',
        activeLabel3: 'Pré-venda',
        flashStateNameE: 'Fim',
        flashStateNameI: 'Em andamento',
        flashStateNameS: 'Não iniciado',
        flashHintSName: 'Iniciar',
        flashHintEName: 'Terminado',
        flashHintIName: 'Tempo restante',
        preSaleStateTimeNO: 'Não iniciado',
        preSaleStateTimeE: 'Fim',
        preSaleStateTimeS: 'Em andamento',
        preSaleHintNoName: 'Iniciar',
        preSaleHintIEName: 'Terminado',
        preSaleHintEName: 'Fim',
        groupHint: 'pessoa',
        reviewTitle: 'Revisão padrão do sistema'
    },
    "appShops": {
        select1: 'Abrangente',
        select2: 'Quantidade de vendas',
        select3: 'Novo produto',
        select4: 'Preço alto para baixo',
        select5: 'Preço baixo a alto',
        select6: 'Classificação',
        infoLabel1: 'Quantidade de vendas',
        infoLabel2: 'Quantidade do produto',
        infoLabel3: 'Quantidade de coleta',
        infoLabel4: 'Avaliar prazo de entrega',
        memberLabel1: 'Dia do Membro',
        memberLabel2: 'Resposta Rápida',
        memberLabel3: 'Remetente Rápido',
        memberLabel4: 'confiável',
        commodity: 'mercadoria',
        classAll: 'todos',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Comentários',
        reviewTitle: 'Comentários padrão do sistema',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Atualização de versão, confirme? ',
        loadingTitle: 'Baixando...',
        systemUpgrade: 'Atualização do sistema',
        upgradeImmediately: 'Confirmar',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Carregando", // Carregando
        finishedText: "Nenhum", //Nenhum
        loadingText: "Carregando", // Carregando
        nullText: 'Ainda não há dados',
        loadingFinished: "Carregamento concluído",
        listNullText: 'Não mais',
        pullIngText: 'Puxe para baixo para atualizar',
        looseText: 'Libere para atualizar',
        pullingSuccess: 'Carregamento suspenso bem-sucedido',
        source: 'confirmar'
    },

};
export default obj;
