// 法语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Maison",
    },
    "searchLanguage": {
        searchBtn: 'Recherche',
        searchPlace: 'Recherche....'
    },
    'setDrawer': {
        label1:'Paramètres multilingues',
        label2: 'Le commerçant s\'est installé',
        label3: 'Connexion marchand',
        label4 : 'Service Client',
        label5: 'Télécharger l\'application Android',
        label6:'Télécharger l\'application iOS',
        optionsBtn: 'Annuler'
    },
    "appService": {
        navTitle:'Service client',
        iconInformation : 'Veuillez sélectionner le logiciel de communication à consulter',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: 'Merchant settled in',
        label1: 'Merchant name',
        label1Hint: 'Merchant name or brand name, once set, cannot be modified',
        visible1: 'Please enter the merchant name',

        label2: 'Mailbox',
        label2Hint: 'The password used by the merchant to log in to the merchant portal. ',
        visible2: 'Please enter your email',
        label3: 'Email verification code',
        label3Hint: 'Code required to confirm merchant identity and prevent malicious activity. Please provide correct verification code information',
        visible3: 'Please enter the email verification code',

        label4: 'Merchant password',
        label4Hint: 'The password used by the merchant to log in to the merchant portal. ',
        visible4: 'Please enter the merchant password',
        label5: 'invitation code',
        visible5: 'Please enter the invitation code',
        label5Hint: 'Enter the invitation code of the merchant that invited you. ',

        label6: 'Store image',
        label6Hint: 'Merchant\'s store image',
        visible6: 'Please enter the verification code',
        label7: 'Merchant Certificate',
        label7Hint: 'Merchant\'s certification documents, such as merchant license, tax registration certificate, etc. These files should be high-quality image files. ',
        visible7: 'Please upload pictures of your merchant license and relevant industry qualification certificates (you can upload up to 2 pictures, each picture has a maximum size of 5MB, and the picture format supports Jpg and Png)',

        placeholderEnter: 'Please enter',
        placeholderSelect: 'Please select',
        sendEmail: 'Send',
        optionBtn1: 'Terms of Service',
        optionBtn2: 'Seller Protection',
        optionBtn3: 'Submit for review',
        uploadMessageError: 'Image upload failed',
        UploadLayoutError: 'Image format error',
        fileFail: 'Please upload & format pictures',
        popupTitle1: 'Terms of Service',
        popupTitle2: 'Seller Protection Agreement'
    },
    'appSetLanguage': {
        navTitle:'Paramètres multilingues',
        affirm : 'confirmer',
    },
    'classifyDrawer': {
        navTitle: 'Catégorie'
    },
    'appHome': {
        recommendTitle: 'Recommander',
        viewAll: 'afficher tout',
        usedTitle: 'Utilisé',
        buyNow: 'Acheter maintenant',
        shopTitle: 'Marchand',
        shopOption: 'Placard',
        hotTitle: 'Chaud',
        ranking: 'Classement des ventes',
        preSaleTitle: 'Prévente',
        preSaleTabs1: 'En cours',
        preSaleTabs2: 'Pas démarré',
        preSaleTabs3: 'Terminé',
        groupTitle: 'Groupe',
        peopleGroupBuying: 'les gens ont participé',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'début',
        preSaleStateTimeE: 'fin',
        showNow: 'Allez faire du shopping maintenant'
    },
    "appShopsListIndex": {
        navTitle: 'Liste des marchands',
        searchPlace: 'Rechercher...',
        favoriteTitle: 'Quantité préférée &',
        shopBtn: 'Voir le placard',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Catégorie',
        viewAll: 'afficher tout',
    },

    'appClassProductIndex': {
        navTitle: 'Produit',
        select1: 'Complet',
        select2: 'Quantité des ventes',
        select3: 'Nouveau produit',
        select4: 'Prix élevé à faible',
        select5: 'Prix bas à haut',
        select6: 'Note',
        paymentTitle: 'Paiement',

    },
    'appPreSale': {
        navTitle: 'Prévente',
        tabs1: 'En cours',
        tabs2: 'Pas démarré',
        tabs3: 'Terminé',
        selectAll: 'Tous',
        listLabel: 'Expédition',
        listLabe2: 'jour',
    },
    "appGroupBuying": {
        navTitle: 'Groupe',
        selectAll: 'Tous',
        listLabel: 'Besoin',
        listLabe2: 'personnes',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Tous',
    },
    'appSalesRanking': {
        navTitle: 'Chaud',
        payment: 'paiement',
        optionBtn1: 'Collecter',
        optionBtn2: 'Acheter maintenant',
    },
    'appLogin': {
        navTitle: 'Connexion',
        navTitleHint: 'Connectez-vous maintenant et commencez à trader avec des amis du monde entier! ',
        singUp: 'S\'inscrire',

        emailLabel: 'Numéro de téléphone portable',
        emailPlaceholder: 'Veuillez saisir votre numéro de téléphone portable',
        emailErrorText: 'Message d\'erreur! ',
        emailNullVerify: 'Veuillez saisir votre numéro de téléphone portable !',//Veuillez saisir votre adresse e-mail
        emailErrorVerify: 'Veuillez saisir votre numéro de téléphone portable! ',//Erreur de format d'e-mail

        passwordLabel: 'votre mot de passe',
        passPlaceholder: 'Veuillez entrer le mot de passe',
        passNullVerify: 'Veuillez entrer votre mot de passe', //Veuillez entrer votre mot de passe
        passErrorVerify: 'Le mot de passe comporte moins de 6caractères! ',//Le mot de passe fait moins de 6 caractères

        placeholder: 'Veuillez entrer',

        emailRegistration: 'Inscrivez-vous maintenant',
        forgetPassTitle: 'Vous avez oublié votre mot de passe? ',

        submitBtnTitle: 'Connexion',
        loginMessage: 'Le compte ou le mot de passe est incorrect! '
    },
    'appForgotPass': {
        navTitle: 'Mot de passe oublié',
        navTitleHint: 'Veuillez indiquer votre numéro de téléphone portable et recevez le code de vérification pour définir un nouveau mot de passe. ',
        emailLabel: 'Numéro de téléphone portable',
        emailPlaceholder: 'Veuillez entrer votre numéro de portable',
        emailErrorText: 'Message d\'erreur !',//Veuillez saisir votre adresse email
        emailNullVerify: 'Veuillez saisir votre numéro de téléphone portable !',//Veuillez saisir votre adresse e-mail
        emailErrorVerify: 'Veuillez saisir votre numéro de téléphone portable!',//Erreur de format d'e-mail
        verificationCode: 'code de vérification',
        verificationPlace: 'code de vérification',
        verificationCodeError: 'Veuillez entrer le code de vérification! ',
        sendTitle: 'Envoyer le code de vérification',
        sendText: 'Revérifier',
        passwordLabel: 'Nouveau mot de passe',
        passPlaceholder: 'Veuillez entrer un nouveau mot de passe',
        confirmPassLabel: 'Confirmer le nouveau mot de passe',
        confirmPlace: 'Confirmer le nouveau mot de passe',
        passNullVerify: 'Entrez votre nouveau mot de passe', //Veuillez entrer votre mot de passe
        passErrorVerify: 'Le mot de passe comporte moins de 6caractères! ',//Le mot de passe fait moins de 6 caractères
        passCompareErrorVerify: 'Les mots de passe saisis deux fois sont incohérents! ',
        passCompareErrorNull: 'Veuillez saisir à nouveau le nouveau mot de passe',
        submitBtnTitle: 'Réinitialiser le mot de passe',
        loginMessage: 'Le code de vérification est incorrect! '
        // emailRegistration : 'Inscription par email',
        // oublierPassTitle: 'Mot de passe oublié ?',

    },
    'appRegister': {
        navTitle: 'S\'inscrire',
        navTitleHint: 'Créez un compte pour accéder à toutes les fonctionnalités ! ',
        namePlace: 'Veuillez saisir',
        firstNameLabel: 'nom',
        firstNamePlace: 'Veuillez entrer',
        firstNameErrorVerify: 'Veuillez entrer votre nom',
        lastNameLabel: 'nom de famille',
        lastNameErrorVerify: 'Veuillez entrer votre nom de famille',
        nickNameLabel: 'surnom',
        nickNameErrorVerify: 'Veuillez entrer votre pseudo',
        emailLabel: 'Numéro de téléphone portable',
        emailPlaceholder: 'Veuillez entrer votre numéro de portable',
        emailErrorText: 'Message d\'erreur !',//Veuillez saisir votre adresse email
        emailNullVerify: 'Veuillez saisir votre numéro de téléphone portable !',//Veuillez saisir votre adresse e-mail
        emailErrorVerify: 'Veuillez saisir votre numéro de téléphone portable! ',//Erreur de format d'e-mail
        verificationCode: 'code de vérification',
        verificationPlace: 'Veuillez entrer le code de vérification',
        sendTitle: 'Envoyer le code de vérification',
        sendText: 'Compte à rebours',
        verificationCodeError: 'Veuillez entrer le code de vérification! ',
        passwordLabel: 'mot de passe',
        passPlaceholder: 'Veuillez entrer le mot de passe',
        confirmPassLabel: 'Confirmer le mot de passe',
        confirmPlace: 'Confirmer le mot de passe',
        passNullVerify: 'Entrez votre mot de passe', // Veuillez entrer votre mot de passe
        passErrorVerify: 'Le mot de passe comporte moins de 6caractères! ',//Le mot de passe fait moins de 6 caractères
        passCompareErrorVerify: 'Les mots de passe saisis deux fois sont incohérents! ',
        passCompareErrorNull: 'Veuillez saisir à nouveau le mot de passe',
        registerHint: 'Vous avez déjà un compte ? ',
        registerHintLogin: 'Connexion',
        submitBtnTitle: 'S\'inscrire',
        loginMessage: 'Le code de vérification est incorrect! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Ventes',
        payHintTitle1: 'Solde',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visa',
        conditionLabel: 'Condition:',
        conditionContent: 'Modes de paiement pris en charge par la marque et la catégorie',
        frightLabel: 'Logistique',
        frightContent: 'Livraison sous 1 à 3 jours en moyenne',
        agreement: 'Accord de protection de l\'acheteur',
        specSelect: 'Veuillez sélectionner une spécification',
        specLabel: 'Spécification',
        specOptions: 'confirmer',
        countLabel: 'Quantité',
        commentTitle: 'Commentaire',
        deliverTime: 'Évaluer le délai et les jours de livraison',
        salesLabel: 'Quantité vendue:',
        productCount: "Quantité du produit:",
        collectLabel: 'Quantité collectée :',
        averageLabel: 'Nombre moyen de jours :',
        since: 'Journée des Membres',
        shipper: 'intervenant rapide',
        responder: ' Expéditeur rapide ',
        reliable: 'fiable',
        btnContact: 'Contact',
        enter: 'placard',
        followBtn: 'Collection',
        guessLike: 'je suppose que tu aimes',
        buyNow: 'Acheter maintenant',
        dialogOverviewTitle: 'Vue d\'ensemble',
        overConditionLabel: 'Condition',
        overConditionContent: 'nouveau',
        overBrandLabel: 'Marque',
        overCategoryLabel: 'Catégorie',
        overPayMethodsLabel1: 'Modes de paiement pris en charge',
        overPayMethodsLabel2: 'Informations importantes',
        overCategoryEnter: 'Confirmer',
        dialogFreightTitle: 'Accord logistique',
        dialogAgreementTitle: 'Accord de protection de l\'acheteur',
        footerLeft: 'Ajouter au panier',
        footerRight: 'Acheter maintenant',
        activeLabel1: 'Achat groupé',
        activeLabel2: 'Deuxième vente',
        activeLabel3: 'Prévente',
        flashStateNameE: 'Fin',
        flashStateNameI: 'En cours',
        flashStateNameS: 'Pas démarré',
        flashHintSName: 'Démarrer',
        flashHintEName: 'Terminé',
        flashHintIName: 'Temps restant',
        preSaleStateTimeNO: 'Pas démarré',
        preSaleStateTimeE: 'Fin',
        preSaleStateTimeS: 'en cours',
        preSaleHintNoName: 'Démarrer',
        preSaleHintIEName: 'Terminé',
        preSaleHintEName: 'Fin',
        groupHint: 'personne',
        reviewTitle: 'Révision par défaut du système'
    },
    "appShops": {
        select1: 'Complet',
        select2: 'Quantité des ventes',
        select3: 'Nouveau produit',
        select4: 'Prix élevé à faible',
        select5: 'Prix bas à haut',
        select6: 'Note',
        infoLabel1: 'Quantité vendue',
        infoLabel2: 'Quantité du produit',
        infoLabel3: 'Quantité collectée',
        infoLabel4: 'Évaluer le délai de livraison',
        memberLabel1: 'Jour des membres',
        memberLabel2: 'Répondeur rapide',
        memberLabel3: 'Expéditeur rapide',
        memberLabel4: 'fiable',
        commodity: 'marchandise',
        classAll: 'tout',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Commentaires',
        reviewTitle: 'Commentaires par défaut du système',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Mise à niveau de la version, veuillez confirmer? ',
        loadingTitle: 'Téléchargement...',
        systemUpgrade: 'Mise à niveau du système',
        upgradeImmediately: 'Confirmer',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Chargement", // Chargement
        finishedText: "Aucun", //Aucun
        loadingText: "Chargement", // Chargement
        nullText: 'Aucune donnée pour l\'instant',
        loadingFinished: "Chargement terminé",
        listNullText: 'Plus rien',
        pullIngText: 'Déroulez pour actualiser',
        looseText: 'Libérer pour actualiser',
        pullingSuccess: 'Chargement déroulant réussi',
        source: 'confirmer'
    },

};
export default obj;
