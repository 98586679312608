// 泰语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "บ้าน",
    },
    "searchLanguage": {
        searchBtn: 'ค้นหา',
        searchPlace: 'ค้นหา....'
    },
    'setDrawer': {
        label1: 'การตั้งค่าหลายภาษา',
        label2: 'พ่อค้าตั้งรกรากใน',
        label3: 'เข้าสู่ระบบร้านค้า',
        label4: 'ฝ่ายบริการลูกค้า',
        label5: 'ดาวน์โหลดแอปพลิเคชัน Android',
        label6: 'ดาวน์โหลดแอปพลิเคชัน iOS',
        optionsBtn: 'ยกเลิก'
    },
    "appService": {
        navTitle: 'ฝ่ายบริการลูกค้า',
        iconInformation: 'โปรดเลือกซอฟต์แวร์การสื่อสารเพื่อรับคำปรึกษา',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: 'พ่อค้าตั้งรกรากใน',
        label1: 'ชื่อผู้ขาย',
        label1Hint: 'ชื่อร้านค้าหรือชื่อแบรนด์ เมื่อตั้งค่าแล้วจะไม่สามารถแก้ไขได้',
        visible1: 'กรุณากรอกชื่อผู้ขาย',

        label2: 'กล่องจดหมาย',
        label2Hint: 'รหัสผ่านที่ผู้ค้าใช้เพื่อเข้าสู่ระบบพอร์ทัลผู้ขาย',
        visible2: 'กรุณากรอกอีเมลของคุณ',
        label3: 'รหัสยืนยันอีเมล',
        label3Hint: 'ต้องใช้รหัสเพื่อยืนยันตัวตนของผู้ขายและป้องกันกิจกรรมที่เป็นอันตราย โปรดระบุข้อมูลรหัสยืนยันที่ถูกต้อง',
        visible3: 'กรุณากรอกรหัสยืนยันอีเมล',

        label4: 'รหัสผ่านผู้ขาย',
        label4Hint: 'รหัสผ่านที่ผู้ค้าใช้เพื่อเข้าสู่ระบบพอร์ทัลผู้ค้า',
        visible4: 'กรุณากรอกรหัสผ่านผู้ขาย',
        label5: 'รหัสคำเชิญ',
        visible5: 'กรุณากรอกรหัสเชิญ',
        label5Hint: 'ป้อนรหัสคำเชิญของผู้ขายที่เชิญคุณ',

        label6: 'จัดเก็บรูปภาพ',
        label6Hint: 'รูปภาพร้านค้าของผู้ขาย',
        visible6: 'กรุณากรอกรหัสยืนยัน',
        label7: 'ใบรับรองผู้ค้า',
        label7Hint: 'เอกสารรับรองของผู้ขาย เช่น ใบอนุญาตผู้ค้า ใบรับรองการจดทะเบียนภาษี ฯลฯ ไฟล์เหล่านี้ควรเป็นไฟล์ภาพคุณภาพสูง',
        visible7: 'โปรดอัปโหลดรูปภาพใบอนุญาตการค้าของคุณและใบรับรองคุณสมบัติอุตสาหกรรมที่เกี่ยวข้อง (คุณสามารถอัปโหลดได้สูงสุด 2 ภาพ แต่ละภาพมีขนาดสูงสุด 5MB และรูปแบบภาพรองรับ JPEG และ PNG)',

        placeholderEnter: 'กรุณาป้อน',
        placeholderSelect: 'โปรดเลือก',
        sendEmail: 'ส่ง',
        optionBtn1: 'ข้อกำหนดในการให้บริการ',
        optionBtn2: 'การคุ้มครองผู้ขาย',
        optionBtn3: 'ส่งเพื่อตรวจสอบ',
        uploadMessageError: 'การอัปโหลดรูปภาพล้มเหลว',
        UploadLayoutError: 'ข้อผิดพลาดรูปแบบภาพ',
        fileFail: 'กรุณาอัพโหลดและจัดรูปแบบรูปภาพ',
        popupTitle1: 'ข้อกำหนดในการให้บริการ',
        popupTitle2: 'ข้อตกลงคุ้มครองผู้ขาย'
    },
    'appSetLanguage': {
        navTitle: 'การตั้งค่าหลายภาษา',
        affirm: 'ยืนยัน'
    },
    'classifyDrawer': {
        navTitle: 'หมวดหมู่'
    },
    'appHome': {
        recommendTitle: 'แนะนำ',
        viewAll: 'ดูทั้งหมด',
        usedTitle: 'ใช้แล้ว',
        buyNow: 'ซื้อเลย',
        shopTitle: 'พ่อค้า',
        shopOption: 'ตู้เสื้อผ้า',
        hotTitle: 'ร้อน',
        ranking: 'อันดับยอดขาย',
        preSaleTitle: 'พรีเซลล์',
        preSaleTabs1: 'กำลังดำเนินการ',
        preSaleTabs2: 'ยังไม่เริ่ม',
        preSaleTabs3: 'เกิน',
        groupTitle: 'กลุ่ม',
        peopleGroupBuying: 'ผู้คนเข้าร่วม',
        flashSaleTitle: 'แฟลช',
        preSaleStateTimeS: 'เริ่มต้น',
        preSaleStateTimeE: 'สิ้นสุด',
        showNow: 'ไปช้อปปิ้งเลย'
    },
    "appShopsListIndex": {
        navTitle: 'รายชื่อผู้ขาย',
        searchPlace: 'ค้นหา...',
        favoriteTitle: 'ปริมาณที่ชื่นชอบ &',
        shopBtn: 'ดูตู้เสื้อผ้า',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'หมวดหมู่',
        viewAll: 'ดูทั้งหมด',
    },

    'appClassProductIndex': {
        navTitle: 'ผลิตภัณฑ์',
        select1: 'ครอบคลุม',
        select2: 'ปริมาณการขาย',
        select3: 'ผลิตภัณฑ์ใหม่',
        select4: 'ราคาสูงไปต่ำ',
        select5: 'ราคาจากต่ำไปสูง',
        select6: 'เรตติ้ง',
        paymentTitle: 'การชำระเงิน'

    },
    'appPreSale': {
        navTitle: 'พรีเซลล์',
        tabs1: 'กำลังดำเนินการ',
        tabs2: 'ยังไม่เริ่ม',
        tabs3: 'จบ',
        selectAll: 'ทั้งหมด',
        listLabel: 'การจัดส่งสินค้า',
        listLabe2: 'วัน'
    },
    "appGroupBuying": {
        navTitle: 'กลุ่ม',
        selectAll: 'ทั้งหมด',
        listLabel: 'จำเป็น',
        listLabe2: 'ผู้คน'
    },
    "appFlashSale": {
        navTitle: 'แฟลช',
        selectAll: 'ทั้งหมด',
    },
    'appSalesRanking': {
        navTitle: 'ร้อนแรง',
        payment: 'การชำระเงิน',
        optionBtn1: 'รวบรวม',
        optionBtn2: 'ซื้อเลย',
    },
    'appLogin': {
        navTitle: 'เข้าสู่ระบบ',
        navTitleHint: 'เข้าสู่ระบบตอนนี้และเริ่มซื้อขายกับเพื่อน ๆ จากทั่วทุกมุมโลก!',
        singUp: 'ลงทะเบียน',

        emailLabel: 'หมายเลขโทรศัพท์มือถือ',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
        emailErrorText: 'ข้อความแสดงข้อผิดพลาด!',
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ!',//กรุณากรอกที่อยู่อีเมลของคุณ
        emailErrorVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ! ',//ข้อผิดพลาดรูปแบบอีเมล

        passwordLabel: 'รหัสผ่านของคุณ',
        passPlaceholder: 'กรุณากรอกรหัสผ่าน',
        passNullVerify: 'กรุณาป้อนรหัสผ่านของคุณ', //กรุณาป้อนรหัสผ่านของคุณ
        passErrorVerify: 'รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร! ',//รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร

        placeholder: 'กรุณาป้อน',

        emailRegistration: 'ลงทะเบียนตอนนี้',
        forgetPassTitle: 'ลืมรหัสผ่าน?',

        submitBtnTitle: 'เข้าสู่ระบบ',
        loginMessage: 'บัญชีหรือรหัสผ่านไม่ถูกต้อง!',
    },
    'appForgotPass': {
        navTitle: 'ลืมรหัสผ่าน',
        navTitleHint: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณและรับรหัสยืนยันเพื่อตั้งรหัสผ่านใหม่',
        emailLabel: 'หมายเลขโทรศัพท์มือถือ',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
        emailErrorText: 'ข้อความแสดงข้อผิดพลาด!',//กรุณากรอกที่อยู่อีเมลของคุณ
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ!',//กรุณากรอกที่อยู่อีเมลของคุณ
        emailErrorVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ!',//ข้อผิดพลาดรูปแบบอีเมล
        verificationCode: 'รหัสยืนยัน',
        verificationPlace: 'รหัสยืนยัน',
        verificationCodeError: 'กรุณากรอกรหัสยืนยัน!',
        sendTitle: 'ส่งรหัสยืนยัน',
        sendText: 'ยืนยันอีกครั้ง',
        passwordLabel: 'รหัสผ่านใหม่',
        passPlaceholder: 'กรุณากรอกรหัสผ่านใหม่',
        confirmPassLabel: 'ยืนยันรหัสผ่านใหม่',
        confirmPlace: 'ยืนยันรหัสผ่านใหม่',
        passNullVerify: 'ป้อนรหัสผ่านใหม่ของคุณ', //กรุณาป้อนรหัสผ่านของคุณ
        passErrorVerify: 'รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร! ',//รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร
        passCompareErrorVerify: 'รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน!',
        passCompareErrorNull: 'กรุณาป้อนรหัสผ่านใหม่อีกครั้ง',
        submitBtnTitle: 'รีเซ็ตรหัสผ่าน',
        loginMessage: 'รหัสยืนยันไม่ถูกต้อง!',
        // emailRegistration: 'การลงทะเบียนอีเมล',
        //forgetPassTitle: 'ลืมรหัสผ่าน?',

    },
    'appRegister': {
        navTitle: 'ลงทะเบียน',
        navTitleHint: 'สร้างบัญชีเพื่อเข้าถึงคุณสมบัติทั้งหมด!',
        namePlace: 'กรุณาระบุ',
        firstNameLabel: 'ชื่อ',
        firstNamePlace: 'กรุณาระบุ',
        firstNameErrorVerify: 'กรุณากรอกชื่อของคุณ',
        lastNameLabel: 'นามสกุล',
        lastNameErrorVerify: 'กรุณากรอกนามสกุลของคุณ',
        nickNameLabel: 'ชื่อเล่น',
        nickNameErrorVerify: 'กรุณากรอกชื่อเล่นของคุณ',
        emailLabel: 'หมายเลขโทรศัพท์มือถือ',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
        emailErrorText: 'ข้อความแสดงข้อผิดพลาด!',//กรุณากรอกที่อยู่อีเมลของคุณ
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ!',//กรุณากรอกที่อยู่อีเมลของคุณ
        emailErrorVerify: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ! ',//ข้อผิดพลาดรูปแบบอีเมล
        verificationCode: 'รหัสยืนยัน',
        verificationPlace: 'กรุณากรอกรหัสยืนยัน',
        sendTitle: 'ส่งรหัสยืนยัน',
        sendText: 'นับถอยหลัง',
        verificationCodeError: 'กรุณากรอกรหัสยืนยัน!',
        passwordLabel: 'รหัสผ่าน',
        passPlaceholder: 'กรุณากรอกรหัสผ่าน',
        confirmPassLabel: 'ยืนยันรหัสผ่าน',
        confirmPlace: 'ยืนยันรหัสผ่าน',
        passNullVerify: 'ป้อนรหัสผ่านของคุณ',//กรุณาป้อนรหัสผ่านของคุณ
        passErrorVerify: 'รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร! ',//รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร
        passCompareErrorVerify: 'รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน!',
        passCompareErrorNull: 'กรุณาใส่รหัสผ่านอีกครั้ง',
        registerHint: 'มีบัญชีอยู่แล้ว?',
        registerHintLogin: 'เข้าสู่ระบบ',
        submitBtnTitle: 'ลงทะเบียน',
        loginMessage: 'รหัสยืนยันไม่ถูกต้อง!',


        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'การขาย',
        payHintTitle1: 'ยอดคงเหลือ',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'วีซ่า',
        conditionLabel: 'เงื่อนไข:',
        conditionContent: 'วิธีการชำระเงินที่รองรับแบรนด์-หมวดหมู่',
        frightLabel: 'โลจิสติกส์',
        frightContent: 'จัดส่งภายใน 1 ~ 3 วันโดยเฉลี่ย',
        agreement: 'ข้อตกลงคุ้มครองผู้ซื้อ',
        specSelect: 'โปรดเลือกข้อมูลจำเพาะ',
        specLabel: 'ข้อมูลจำเพาะ',
        specOptions: 'ยืนยัน',
        countLabel: 'ปริมาณ',
        commentTitle: 'ความคิดเห็น',
        deliverTime: 'ประเมินเวลาและวันที่จัดส่ง',
        salesLabel: 'ปริมาณการขาย:',
        productCount: "ปริมาณผลิตภัณฑ์:",
        collectLabel: 'ปริมาณการรวบรวม:',
        averageLabel: 'จำนวนวันเฉลี่ย:',
        since: 'วันสมาชิก',
        shipper: 'ตอบกลับด่วน',
        responder: 'ผู้จัดส่งด่วน',
        reliable: 'เชื่อถือได้',
        btnContact: 'ติดต่อ',
        enter: 'ตู้เสื้อผ้า',
        followBtn: 'คอลเลกชัน',
        guessLike: 'เดาว่าคุณชอบ',
        buyNow: 'ซื้อเลย',
        dialogOverviewTitle: 'ภาพรวม',
        overConditionLabel: 'เงื่อนไข',
        overConditionContent: 'ใหม่',
        overBrandLabel: 'แบรนด์',
        overCategoryLabel: 'หมวดหมู่',
        overPayMethodsLabel1: 'วิธีการชำระเงินที่รองรับ',
        overPayMethodsLabel2: 'ข้อมูลสำคัญ',
        overCategoryEnter: 'ยืนยัน',
        dialogFreightTitle: 'ข้อตกลงด้านลอจิสติกส์',
        dialogAgreementTitle: 'ข้อตกลงคุ้มครองผู้ซื้อ',
        footerLeft: 'หยิบลงตะกร้า',
        footerRight: 'ซื้อเลย',
        activeLabel1: 'การซื้อแบบกลุ่ม',
        activeLabel2: 'การขายครั้งที่สอง',
        activeLabel3: 'พรีเซลล์',
        flashStateNameE: 'สิ้นสุด',
        flashStateNameI: 'กำลังดำเนินการ',
        flashStateNameS: 'ยังไม่เริ่ม',
        flashHintSName: 'เริ่ม',
        flashHintEName: 'สิ้นสุดแล้ว',
        flashHintIName: 'เวลาที่เหลืออยู่',
        preSaleStateTimeNO: 'ยังไม่เริ่ม',
        preSaleStateTimeE: 'สิ้นสุด',
        preSaleStateTimeS: 'กำลังดำเนินอยู่',
        preSaleHintNoName: 'เริ่ม',
        preSaleHintIEName: 'สิ้นสุดแล้ว',
        preSaleHintEName: 'สิ้นสุด',
        groupHint: 'บุคคล',
        reviewTitle: 'การตรวจสอบเริ่มต้นของระบบ'
    },
    "appShops": {
        select1: 'ครอบคลุม',
        select2: 'ปริมาณการขาย',
        select3: 'ผลิตภัณฑ์ใหม่',
        select4: 'ราคาสูงไปต่ำ',
        select5: 'ราคาจากต่ำไปสูง',
        select6: 'เรตติ้ง',
        infoLabel1: 'ปริมาณการขาย',
        infoLabel2: 'ปริมาณผลิตภัณฑ์',
        infoLabel3: 'ปริมาณคอลเลกชัน',
        infoLabel4: 'ประเมินเวลาจัดส่ง',
        memberLabel1: 'วันสมาชิก',
        memberLabel2: 'ตอบกลับด่วน',
        memberLabel3: 'ผู้จัดส่งด่วน',
        memberLabel4: 'เชื่อถือได้',
        commodity: 'สินค้าโภคภัณฑ์',
        classAll: 'ทั้งหมด'

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'ความคิดเห็น',
        reviewTitle: 'ความคิดเห็นเริ่มต้นของระบบ',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'อัปเกรดเวอร์ชัน โปรดยืนยันหรือไม่?',
        loadingTitle: 'กำลังดาวน์โหลด...',
        systemUpgrade: 'อัปเกรดระบบ',
        upgradeImmediately: 'ยืนยัน'
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "กำลังโหลด", // กำลังโหลด
        finishedText: "ไม่มี", //ไม่มี
        loadingText: "กำลังโหลด", // กำลังโหลด
        nullText: 'ยังไม่มีข้อมูล',
        loadingFinished: "กำลังโหลดเสร็จสมบูรณ์",
        listNullText: 'ไม่มีอีกแล้ว',
        pullIngText: 'ดึงลงเพื่อรีเฟรช',
        looseText: 'ปล่อยเพื่อรีเฟรช',
        pullingSuccess: 'การโหลดแบบดึงลงสำเร็จ',
        source: 'ยืนยัน'
    },

};
export default obj;
