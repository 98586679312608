// 繁体中文

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "首頁",
  },
  "searchLanguage": {
    searchBtn: '搜尋',
    searchPlace: '搜尋....'
  },
  'setDrawer': {
    label1: '多語言設定',
    label2: '商家進駐',
    label3: '前往店鋪',
    label4: '客戶服務',
    label5: '下載 Android 應用程式',
    label6: '下載ios應用程式',
    optionsBtn: '取消'
  },
  "appService": {
    navTitle: '顧客服務',
    iconInformation: '請選擇通訊軟體進行諮詢',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'KakaoTalk',
    signal: 'Signal'
  },
  "appMerchantSettlement": {
    navTitle: '商家入駐',
    label1: '商家名稱',
    label1Hint: '商家名稱或品牌名稱，一旦設定不可修改',
    visible1: '請輸入商家名稱',

    label2: '郵箱',
    label2Hint: '商家用於登入商家入口網站的密碼。',
    visible2: '請輸入郵箱',
    label3: '郵箱驗證碼',
    label3Hint: '確認商家身分並防止惡意活動所需的程式碼。請提供正確的驗證碼訊息',
    visible3: '請輸入郵箱驗證碼',

    label4: '商家密碼',
    label4Hint: '商家用於登录商家入口的密碼。',
    visible4: '請輸入商家密碼',
    label5: '邀請碼',
    visible5: '請輸入邀請碼',
    label5Hint: '輸入邀請您的商家邀請碼。',

    label6: '店铺圖像',
    label6Hint: '商家的店鋪圖像',
    visible6: '請輸入驗證碼',
    label7: '商家證書',
    label7Hint: '商戶的證明文件，如營業執照、稅務登記證等。 這些文件應為高品質的圖像文件。',
    visible7: '請上傳營業執照圖片及相關產業資質證明（最多可上傳2張圖片，每張圖片最大尺寸5MB，圖片格式支援Jpg、Png）',

    placeholderEnter: '請輸入',
    placeholderSelect: '請選擇',
    sendEmail: '傳送',
    optionBtn1: '服務條款',
    optionBtn2: '賣家保護',
    optionBtn3: '提交審核',
    uploadMessageError: '圖片上傳失敗',
    UploadLayoutError: '圖片格式錯誤',
    fileFail: '請上傳&格式圖片',
    popupTitle1:'服務條款',
    popupTitle2:'賣家保護協定'
  },
  'appSetLanguage': {
    navTitle: '多語言設定',
    affirm: '確認',
  },
  'classifyDrawer': {
    navTitle: '類別'
  },
  'appHome': {
    recommendTitle: '推薦',
    viewAll: '查看全部',
    usedTitle: '用過的',
    buyNow: '立即購買',
    shopTitle: '商家',
    shopOption: '查看壁橱',
    hotTitle: '熱門名單',
    ranking: '銷售排名',
    preSaleTitle: '預售',
    preSaleTabs1: '進行中',
    preSaleTabs2: '未開始',
    preSaleTabs3: '已結束',
    groupTitle: '團購',
    peopleGroupBuying: '人參與',
    flashSaleTitle: '閃購',
    preSaleStateTimeS: '開始',
    preSaleStateTimeE: '結束',
    showNow: '現在去購物'
  },
  "appShopsListIndex": {
    navTitle: '商家列表',
    searchPlace: '搜尋...',
    favoriteTitle: '最喜歡的數量 &',
    shopBtn: '查看壁櫥',
  },
  // 移动分類页
  'appClassification': {
    navTitle: '分類',
    viewAll: '查看全部',
  },

  'appClassProductIndex': {
    navTitle: '商品',
    select1: '綜合排序',
    select2: '銷售數量',
    select3: '新商品',
    select4: '價格從高到低',
    select5: '價格從低到高',
    select6: '評分',
    paymentTitle: '付款',

  },
  'appPreSale': {
    navTitle: '預售',
    tabs1: '進行中',
    tabs2: '未開始',
    tabs3: '已結束',
    selectAll: '所有',
    listLabel: '運輸',
    listLabe2: '天',
  },
  "appGroupBuying": {
    navTitle: '團購',
    selectAll: '所有',
    listLabel: '需要',
    listLabe2: '人',
  },
  "appFlashSale": {
    navTitle: '閃購',
    selectAll: '所有',
  },
  'appSalesRanking': {
    navTitle: '熱門名單',
    payment: '付款',
    optionBtn1: '收藏',
    optionBtn2: '立即購買',
  },
  'appLogin': {
    navTitle: '登录',
    navTitleHint: '立即登录並開始與來自世界各地的朋友交易',
    singUp: '註冊',

    emailLabel: '手機號碼',
    emailPlaceholder: '請輸入手機號',
    emailErrorText: '錯誤提示！',
    emailNullVerify: '請輸入手機號 !',//請輸入郵箱
    emailErrorVerify: '請輸入手機號碼！',//郵箱格式错误

    passwordLabel: '你的密碼',
    passPlaceholder: '請輸入密碼',
    passNullVerify: '請輸入您的密碼',//請輸入密碼
    passErrorVerify: '密碼小於6位！',//密碼小於6位

    placeholder: '請輸入',

    emailRegistration: '立即註冊',
    forgetPassTitle: '忘記密碼？',

    submitBtnTitle: '登录',
    loginMessage: '帳號或者密碼有误！'
  },
  'appForgotPass': {
    navTitle: '忘記密碼',
    navTitleHint: '請填寫您的手機號碼並且接收驗證碼以設定新密碼。',
    emailLabel: '手機號碼',
    emailPlaceholder: '請輸入手機號碼',
    emailErrorText: '錯誤提示!',//請輸入郵箱
    emailNullVerify: '請輸入手機號碼!',//請輸入郵箱
    emailErrorVerify: '請輸入手機號碼!',//郵箱格式错误
    verificationCode: '驗證碼',
    verificationPlace: '驗證碼',
    verificationCodeError: '請輸入驗證碼！',
    sendTitle: '傳送驗證碼',
    sendText: '重新驗證',
    passwordLabel: '新密碼',
    passPlaceholder: '請輸入新密碼',
    confirmPassLabel: '確認新密碼',
    confirmPlace: '確認新密碼',
    passNullVerify: '輸入您的新密碼',//請輸入密碼
    passErrorVerify: '密碼小於6位！',//密碼小於6位
    passCompareErrorVerify: '两次輸入的密碼不一致！',
    passCompareErrorNull: '請再次輸入新密碼',
    submitBtnTitle: '重設密碼',
    loginMessage: '驗證碼不正確！'
    // emailRegistration: 'Email registration',
    // forgetPassTitle: 'Forgot Password?',

  },
  'appRegister': {
    navTitle: '註冊',
    navTitleHint: '建立一個帳戶以存取所有功能！',
    namePlace: '請輸入',
    firstNameLabel: '名字',
    firstNamePlace: '請輸入',
    firstNameErrorVerify: '請輸入您的名字',
    lastNameLabel: '姓',
    lastNameErrorVerify: '請輸入您的姓氏',
    nickNameLabel: '暱稱',
    nickNameErrorVerify: '請輸入您的暱稱',
    emailLabel: '手機號碼',
    emailPlaceholder: '請輸入手機號碼',
    emailErrorText: '錯誤提示!',//請輸入郵箱
    emailNullVerify: '請輸入手機號碼!',//請輸入郵箱
    emailErrorVerify: '請輸入手機號碼！',//郵箱格式错误
    verificationCode: '驗證碼',
    verificationPlace: '請輸入驗證碼',
    sendTitle: '傳送驗證碼',
    sendText: '倒數計時',
    verificationCodeError: '請輸入驗證碼！',
    passwordLabel: '密碼',
    passPlaceholder: '請輸入密碼',
    confirmPassLabel: '確認密碼',
    confirmPlace: '確認密碼',
    passNullVerify: '輸入您的密碼',//請輸入密碼
    passErrorVerify: '密碼小於6位！',//密碼小於6位
    passCompareErrorVerify: '两次輸入的密碼不一致！',
    passCompareErrorNull: '請再次輸入密碼',
    registerHint: '已經有帳戶？',
    registerHintLogin: '登录',
    submitBtnTitle: '註冊',
    loginMessage: '驗證碼不正確！'

    // gender: 'Gender',
    // gender1: 'Boy',
    // gender2: 'Girl',
    // gender3: 'Secrecy',
    // genderError: 'Please enter your gender',
  },
  'appGoodsDetail': {
    salesTitle: '銷售',
    payHintTitle1: '餘額',
    payHintTitle2: '貝寶',
    payHintTitle3: 'Visa',
    conditionLabel: '條件:',
    conditionContent: '品牌-分類-支持支付方式',
    frightLabel: '物流',
    frightContent: '平均1~3天內出貨',
    agreement: '買家保護協議',
    specSelect: '請選擇規格',
    specLabel: '規格',
    specOptions: '確認',
    countLabel: '數量',
    commentTitle: '評論',
    deliverTime: '評估交貨時間和天數',
    salesLabel: '銷售數量:',
    productCount: "商品數量:",
    collectLabel: '收藏數量:',
    averageLabel: '平均天數:',
    since: '會員日',
    shipper: '快速響應者',
    responder: '快速出貨人',
    reliable: '可靠的',
    btnContact: '聯繫',
    enter: '壁櫥',
    followBtn: '收藏',
    guessLike: '猜你喜歡',
    buyNow: '立即購買',
    dialogOverviewTitle: '概覽',
    overConditionLabel: '條件',
    overConditionContent: '新的',
    overBrandLabel: '品牌',
    overCategoryLabel: '分類',
    overPayMethodsLabel1: '支持的支付方式',
    overPayMethodsLabel2: '重要的訊息',
    overCategoryEnter: '確認',
    dialogFreightTitle: '物流協定',
    dialogAgreementTitle: '買家保護協議',
    footerLeft: '新增購物車',
    footerRight: '立即購買',
    activeLabel1: '團購',
    activeLabel2: '秒殺',
    activeLabel3: '預售',
    flashStateNameE: '結束',
    flashStateNameI: '進行中',
    flashStateNameS: '未開始',
    flashHintSName: '開始',
    flashHintEName: '已結束',
    flashHintIName: '剩餘時間',
    preSaleStateTimeNO: '未開始',
    preSaleStateTimeE: '結束',
    preSaleStateTimeS: '進行中',
    preSaleHintNoName: '開始',
    preSaleHintIEName: '已結束',
    preSaleHintEName: '結束',
    groupHint: '人',
    reviewTitle: '系統預設評論'
  },
  "appShops": {
    select1: '綜合排序',
    select2: '銷售數量',
    select3: '新商品',
    select4: '價格從高到低',
    select5: '價格從低到高',
    select6: '評分',
    infoLabel1: '銷售數量',
    infoLabel2: '商品數量',
    infoLabel3: '收藏數量',
    infoLabel4: '評估交貨時間',
    memberLabel1: '會員日',
    memberLabel2: '快速響應者',
    memberLabel3: '快速出貨人',
    memberLabel4: '可靠的',
    commodity: '商品',
    classAll: '所有',

    // timeDay: 'Evaluate Delivery time & days',
    // productQuantity: 'Product Quantity',
    // shippedQuantity: 'Shipped Quantity',
    // collections: "Number of Collections",
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 商品評論页
  'appProductReviews': {
    navTitle: '評論',
    reviewTitle: '系統預設評論',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '版本升級,請確認？ ',
    loadingTitle: '正在下載...',
    systemUpgrade: '系統升級',
    upgradeImmediately: '確認',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "加載中", // 正在加載
    finishedText: "無", //無
    loadingText: "加載中", // 正在加載
    nullText: '暫無數據',
    loadingFinished: "載入完成",
    listNullText: '没有更多了',
    pullIngText: '下拉即可刷新',
    loosingText: '释放即可刷新',
    pullingSuccess: '下拉載入成功',
    source: '確認'
  },

};
export default obj;
