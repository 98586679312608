// 英文

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Home",
  },
  "searchLanguage": {
    searchBtn: 'Search',
    searchPlace: 'Search....'
  },
  'setDrawer': {
    label1: 'Multi-language settings',
    label2: 'Merchant settled in',
    label3: 'Merchant login',
    label4: 'Customer Service',
    label5: 'Download Android application',
    label6: 'Download ios application',
    optionsBtn: 'Cancel'
  },
  "appService": {
    navTitle: 'Customer Service',
    iconInformation: 'Please select communication software for consultation',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'KakaoTalk',
    signal: 'Signal'
  },
  "appMerchantSettlement": {
    navTitle: 'Merchant settled in',
    label1: 'Merchant name',
    label1Hint: 'Merchant name or brand name, once set, cannot be modified',
    visible1: 'Please enter the merchant name',

    label2: 'Mailbox',
    label2Hint: 'The password used by the merchant to log in to the merchant portal. ',
    visible2: 'Please enter your email',
    label3: 'Email verification code',
    label3Hint: 'Code required to confirm merchant identity and prevent malicious activity. Please provide correct verification code information',
    visible3: 'Please enter the email verification code',

    label4: 'Merchant password',
    label4Hint: 'The password used by the merchant to log in to the merchant portal. ',
    visible4: 'Please enter the merchant password',
    label5: 'invitation code',
    visible5: 'Please enter the invitation code',
    label5Hint: 'Enter the invitation code of the merchant that invited you. ',

    label6: 'Store image',
    label6Hint: 'Merchant\'s store image',
    visible6: 'Please enter the verification code',
    label7: 'Merchant Certificate',
    label7Hint: 'Merchant\'s certification documents, such as merchant license, tax registration certificate, etc. These files should be high-quality image files. ',
    visible7: 'Please upload pictures of your merchant license and relevant industry qualification certificates (you can upload up to 2 pictures, each picture has a maximum size of 5MB, and the picture format supports Jpg and Png)',

    placeholderEnter: 'Please enter',
    placeholderSelect: 'Please select',
    sendEmail: 'Send',
    optionBtn1: 'Terms of Service',
    optionBtn2: 'Seller Protection',
    optionBtn3: 'Submit for review',
    uploadMessageError: 'Image upload failed',
    UploadLayoutError: 'Image format error',
    fileFail: 'Please upload & format pictures',
    popupTitle1:'Terms of Service',
    popupTitle2:'Seller Protection Agreement'
  },
  'appSetLanguage': {
    navTitle: 'Multi-language settings',
    affirm: 'confirm',
  },
  'classifyDrawer': {
    navTitle: 'Category'
  },
  'appHome': {
    recommendTitle: 'Recommend',
    viewAll: 'view all',
    usedTitle: 'Used',
    buyNow: 'Buy Now',
    shopTitle: 'Merchant',
    shopOption: 'Closet',
    hotTitle: 'Hot',
    ranking: 'Sales Ranking',
    preSaleTitle: 'Presale',
    preSaleTabs1: 'Ongoing',
    preSaleTabs2: 'Not started',
    preSaleTabs3: 'Over',
    groupTitle: 'Group',
    peopleGroupBuying: 'people participated',
    flashSaleTitle: 'Flash',
    preSaleStateTimeS: 'start',
    preSaleStateTimeE: 'end',
    showNow: 'Go shopping now'
  },
  "appShopsListIndex": {
    navTitle: 'Merchant List',
    searchPlace: 'Search...',
    favoriteTitle: 'Favorite Quantity &',
    shopBtn: 'View Closet',
  },
  // 移动分类页
  'appClassification': {
    navTitle: 'Category',
    viewAll: 'view all',
  },

  'appClassProductIndex': {
    navTitle: 'Product',
    select1: 'Comprehensive',
    select2: 'Sales quantity',
    select3: 'New product',
    select4: 'Price high to low',
    select5: 'Price low to high',
    select6: 'Rating',
    paymentTitle: 'Payment',

  },
  'appPreSale': {
    navTitle: 'Presale',
    tabs1: 'Ongoing',
    tabs2: 'Not started',
    tabs3: 'Over',
    selectAll: 'All',
    listLabel: 'Shipping',
    listLabe2: 'day',
  },
  "appGroupBuying": {
    navTitle: 'Group',
    selectAll: 'All',
    listLabel: 'Need',
    listLabe2: 'people',
  },
  "appFlashSale": {
    navTitle: 'Flash',
    selectAll: 'All',
  },
  'appSalesRanking': {
    navTitle: 'Hot',
    payment: 'payment',
    optionBtn1: 'Collect',
    optionBtn2: 'Buy Now',
  },
  'appLogin': {
    navTitle: 'Login',
    navTitleHint: 'Log in now and start trading with friends from all over the world! ',
    singUp: 'Register',

    emailLabel: 'Mobile phone number',
    emailPlaceholder: 'Please enter your mobile phone number',
    emailErrorText: 'Error message! ',
    emailNullVerify: 'Please enter your mobile phone number!',//Please enter your email address
    emailErrorVerify: 'Please enter your mobile phone number! ',//Email format error

    passwordLabel: 'your password',
    passPlaceholder: 'Please enter password',
    passNullVerify: 'Please enter your password', //Please enter your password
    passErrorVerify: 'The password is less than 6 characters! ',//The password is less than 6 characters

    placeholder: 'Please enter',

    emailRegistration: 'Register now',
    forgetPassTitle: 'Forgot your password? ',

    submitBtnTitle: 'Login',
    loginMessage: 'Account or password is incorrect! '
  },
  'appForgotPass': {
    navTitle: 'Forgot password',
    navTitleHint: 'Please fill in your mobile phone number and receive the verification code to set a new password. ',
    emailLabel: 'Mobile phone number',
    emailPlaceholder: 'Please enter your mobile number',
    emailErrorText: 'Error message!',//Please enter your email address
    emailNullVerify: 'Please enter your mobile phone number!',//Please enter your email address
    emailErrorVerify: 'Please enter your mobile phone number!',//Email format error
    verificationCode: 'verification code',
    verificationPlace: 'verification code',
    verificationCodeError: 'Please enter the verification code! ',
    sendTitle: 'Send verification code',
    sendText: 'Reverify',
    passwordLabel: 'New password',
    passPlaceholder: 'Please enter a new password',
    confirmPassLabel: 'Confirm new password',
    confirmPlace: 'Confirm new password',
    passNullVerify: 'Enter your new password', //Please enter your password
    passErrorVerify: 'The password is less than 6 characters! ',//The password is less than 6 characters
    passCompareErrorVerify: 'The passwords entered twice are inconsistent! ',
    passCompareErrorNull: 'Please enter the new password again',
    submitBtnTitle: 'Reset password',
    loginMessage: 'The verification code is incorrect! '
    // emailRegistration: 'Email registration',
    // forgetPassTitle: 'Forgot Password?',

  },
  'appRegister': {
    navTitle: 'Register',
    navTitleHint: 'Create an account to access all features! ',
    namePlace: 'Please enter',
    firstNameLabel: 'name',
    firstNamePlace: 'Please enter',
    firstNameErrorVerify: 'Please enter your name',
    lastNameLabel: 'last name',
    lastNameErrorVerify: 'Please enter your last name',
    nickNameLabel: 'nickname',
    nickNameErrorVerify: 'Please enter your nickname',
    emailLabel: 'Mobile phone number',
    emailPlaceholder: 'Please enter your mobile number',
    emailErrorText: 'Error message!',//Please enter your email address
    emailNullVerify: 'Please enter your mobile phone number!',//Please enter your email address
    emailErrorVerify: 'Please enter your mobile phone number! ',//Email format error
    verificationCode: 'verification code',
    verificationPlace: 'Please enter the verification code',
    sendTitle: 'Send verification code',
    sendText: 'Countdown',
    verificationCodeError: 'Please enter the verification code! ',
    passwordLabel: 'password',
    passPlaceholder: 'Please enter password',
    confirmPassLabel: 'Confirm password',
    confirmPlace: 'Confirm password',
    passNullVerify: 'Enter your password',//Please enter your password
    passErrorVerify: 'The password is less than 6 characters! ',//The password is less than 6 characters
    passCompareErrorVerify: 'The passwords entered twice are inconsistent! ',
    passCompareErrorNull: 'Please enter password again',
    registerHint: 'Already have an account? ',
    registerHintLogin: 'Login',
    submitBtnTitle: 'Register',
    loginMessage: 'The verification code is incorrect! '

    // gender: 'Gender',
    // gender1: 'Boy',
    // gender2: 'Girl',
    // gender3: 'Secrecy',
    // genderError: 'Please enter your gender',
  },
  'appGoodsDetail': {
    salesTitle: 'Sales',
    payHintTitle1: 'Balance',
    payHintTitle2: 'PayPal',
    payHintTitle3: 'Visa',
    conditionLabel: 'Condition:',
    conditionContent: 'Brand-Category-Supported Payment Methods',
    frightLabel: 'Logistics',
    frightContent: 'Delivery within 1~3 days on average',
    agreement: 'Buyer Protection Agreement',
    specSelect: 'Please select a specification',
    specLabel: 'Specification',
    specOptions: 'confirm',
    countLabel: 'Quantity',
    commentTitle: 'Comment',
    deliverTime: 'Evaluate delivery time and days',
    salesLabel: 'Sales quantity:',
    productCount: "Product quantity:",
    collectLabel: 'Collection quantity:',
    averageLabel: 'Average number of days:',
    since: 'Member Day',
    shipper: 'quick responder',
    responder: 'Quick Shipper',
    reliable: 'reliable',
    btnContact: 'Contact',
    enter: 'closet',
    followBtn: 'Collection',
    guessLike: 'guess you like',
    buyNow: 'Buy now',
    dialogOverviewTitle: 'Overview',
    overConditionLabel: 'Condition',
    overConditionContent: 'new',
    overBrandLabel: 'Brand',
    overCategoryLabel: 'Category',
    overPayMethodsLabel1: 'Supported payment methods',
    overPayMethodsLabel2: 'Important information',
    overCategoryEnter: 'Confirm',
    dialogFreightTitle: 'Logistics Agreement',
    dialogAgreementTitle: 'Buyer Protection Agreement',
    footerLeft: 'Add to cart',
    footerRight: 'Buy now',
    activeLabel1: 'Group purchase',
    activeLabel2: 'Second Sale',
    activeLabel3: 'Presale',
    flashStateNameE: 'End',
    flashStateNameI: 'Ongoing',
    flashStateNameS: 'Not started',
    flashHintSName: 'Start',
    flashHintEName: 'Ended',
    flashHintIName: 'Remaining time',
    preSaleStateTimeNO: 'Not started',
    preSaleStateTimeE: 'End',
    preSaleStateTimeS: 'Ongoing',
    preSaleHintNoName: 'Start',
    preSaleHintIEName: 'Ended',
    preSaleHintEName: 'End',
    groupHint: 'person',
    reviewTitle: 'System default review'
  },
  "appShops": {
    select1: 'Comprehensive',
    select2: 'Sales quantity',
    select3: 'New product',
    select4: 'Price high to low',
    select5: 'Price low to high',
    select6: 'Rating',
    infoLabel1: 'Sales quantity',
    infoLabel2: 'Product quantity',
    infoLabel3: 'Collection quantity',
    infoLabel4: 'Evaluate delivery time',
    memberLabel1: 'Member Day',
    memberLabel2: 'Quick Responder',
    memberLabel3: 'Quick Shipper',
    memberLabel4: 'reliable',
    commodity: 'commodity',
    classAll: 'all',

    // timeDay: 'Evaluate Delivery time & days',
    // productQuantity: 'Product Quantity',
    // shippedQuantity: 'Shipped Quantity',
    // collections: "Number of Collections",
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 商品评论页
  'appProductReviews': {
    navTitle: 'Comments',
    reviewTitle: 'System default comments',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version upgrade, please confirm? ',
    loadingTitle: 'Downloading...',
    systemUpgrade: 'System upgrade',
    upgradeImmediately: 'Confirm',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // Loading
    finishedText: "None", //None
    loadingText: "Loading", // Loading
    nullText: 'No data yet',
    loadingFinished: "Loading completed",
    listNullText: 'No more',
    pullIngText: 'Pull down to refresh',
    looseText: 'Release to refresh',
    pullingSuccess: 'Pull-down loading successful',
    source: 'confirm'
  },

};
export default obj;
