// 德语

let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Heim",
    },
    "searchLanguage": {
        searchBtn: 'Suchen',
        searchPlace: 'Suchen....'
    },
    'setDrawer': {
        label1: 'Mehrsprachige Einstellungen',
        label2: 'Händler hat sich angemeldet',
        label3: 'Händler-Login',
        label4: 'Kundenservice',
        label5: 'Android-Anwendung herunterladen',
        label6: 'iOS-Anwendung herunterladen',
        optionsBtn: 'Abbrechen'
    },
    "appService": {
        navTitle: 'Kundenservice',
        iconInformation: 'Bitte wählen Sie eine Kommunikationssoftware für die Beratung aus',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
        kakao: 'KakaoTalk',
        signal: 'Signal'
    },
    "appMerchantSettlement": {
        navTitle: 'Händler hat sich angemeldet',
        label1: 'Händlername',
        label1Hint: 'Händlername oder Markenname können nach der Festlegung nicht mehr geändert werden',
        visible1: 'Bitte geben Sie den Händlernamen ein',
        label2: 'Mailbox',
        label2Hint: 'Das vom Händler verwendete Passwort zum Anmelden beim Händlerportal.',
        visible2: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        label3: 'E-Mail-Bestätigungscode',
        label3Hint: 'Code erforderlich, um die Identität des Händlers zu bestätigen und böswillige Aktivitäten zu verhindern. Bitte geben Sie korrekte Informationen zum Bestätigungscode an',
        visible3: 'Bitte geben Sie den E-Mail-Bestätigungscode ein',

        label4: 'Händlerpasswort',
        label4Hint: 'Das vom Händler verwendete Passwort zum Anmelden beim Händlerportal.',
        visible4: 'Bitte geben Sie das Händlerpasswort ein',
        label5: 'Einladungscode',
        visible5: 'Bitte geben Sie den Einladungscode ein',
        label5Hint: 'Geben Sie den Einladungscode des Händlers ein, der Sie eingeladen hat. ',

        label6: 'Shopbild',
        label6Hint: 'Shopbild des Händlers',
        visible6: 'Bitte geben Sie den Bestätigungscode ein',
        label7: 'Händlerzertifikat',
        label7Hint: 'Zertifizierungsdokumente des Händlers, wie z. B. Händlerlizenz, Steuerregistrierungszertifikat usw. Diese Dateien sollten qualitativ hochwertige Bilddateien sein. ',
        visible7: 'Bitte laden Sie Bilder Ihrer Händlerlizenz und relevanter Branchenqualifikationszertifikate hoch (Sie können bis zu 2 Bilder hochladen, jedes Bild hat eine maximale Größe von 5 MB und das Bildformat unterstützt JPG und PNG)',

        placeholderEnter: 'Bitte eingeben',
        placeholderSelect: 'Bitte auswählen',
        sendEmail: 'Senden',
        optionBtn1: 'Servicebedingungen',
        optionBtn2: 'Verkäuferschutz',
        optionBtn3: 'Zur Überprüfung einreichen',
        uploadMessageError: 'Bildupload fehlgeschlagen',
        UploadLayoutError: 'Bildformatfehler',
        fileFail: 'Bitte Bilder hochladen und formatieren',
        popupTitle1: 'Servicebedingungen',
        popupTitle2: 'Verkäuferschutzvereinbarung'
    },
    'appSetLanguage': {
        navTitle: 'Mehrsprachige Einstellungen',
        affirm: 'Bestätigen',
    },
    'classifyDrawer': {
        navTitle: 'Kategorie'
    },
    'appHome': {
        recommendTitle: 'Empfehlen',
        viewAll: 'alles anzeigen',
        usedTitle: 'Gebraucht',
        buyNow: 'Jetzt kaufen',
        shopTitle: 'Händler',
        shopOption: 'Kleiderschrank',
        hotTitle: 'Heiß',
        ranking: 'Verkaufsranking',
        preSaleTitle: 'Vorverkauf',
        preSaleTabs1: 'Laufend',
        preSaleTabs2: 'Nicht gestartet',
        preSaleTabs3: 'Beendet',
        groupTitle: 'Gruppe',
        peopleGroupBuying: 'Personen haben teilgenommen',
        flashSaleTitle: 'Flash',
        preSaleStateTimeS: 'Start',
        preSaleStateTimeE: 'Ende',
        showNow: 'Jetzt einkaufen gehen'
    },
    "appShopsListIndex": {
        navTitle: 'Händlerliste',
        searchPlace: 'Suchen...',
        favoriteTitle: 'Favoritenmenge &',
        shopBtn: 'Kleiderschrank anzeigen',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Kategorie',
        viewAll: 'alle anzeigen',
    },

    'appClassProductIndex': {
        navTitle: 'Produkt',
        select1: 'Umfassend',
        select2: 'Verkaufsmenge',
        select3: 'Neues Produkt',
        select4: 'Preis hoch bis niedrig',
        select5: 'Preis niedrig bis hoch',
        select6: 'Bewertung',
        paymentTitle: 'Zahlung',

    },
    'appPreSale': {
        navTitle: 'Vorverkauf',
        tabs1: 'Laufend',
        tabs2: 'Nicht begonnen',
        tabs3: 'Vorbei',
        selectAll: 'Alle',
        listLabel: 'Versand',
        listLabe2: 'Tag',
    },
    "appGroupBuying": {
        navTitle: 'Gruppe',
        selectAll: 'Alle',
        listLabel: 'Benötigen',
        listLabe2: 'Personen',
    },
    "appFlashSale": {
        navTitle: 'Flash',
        selectAll: 'Alle',
    },
    'appSalesRanking': {
        navTitle: 'Hot',
        payment: 'Zahlung',
        optionBtn1: 'Sammeln',
        optionBtn2: 'Jetzt kaufen',
    },
    'appLogin': {
        navTitle: 'Anmelden',
        navTitleHint: 'Melden Sie sich jetzt an und beginnen Sie mit Freunden aus aller Welt zu handeln! ',
        singUp: 'Registrieren',

        emailLabel: 'Mobiltelefonnummer',
        emailPlaceholder: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
        emailErrorText: 'Fehlermeldung!',
        emailNullVerify: 'Bitte geben Sie Ihre Mobiltelefonnummer ein!',//Bitte geben Sie Ihre E-Mail-Adresse ein
        emailErrorVerify: 'Bitte geben Sie Ihre Mobiltelefonnummer ein!',//Fehler im E-Mail-Format

        passwordLabel: 'Ihr Passwort',
        passPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
        passNullVerify: 'Bitte geben Sie Ihr Passwort ein', //Bitte geben Sie Ihr Passwort ein
        passErrorVerify: 'Das Passwort ist weniger als 6 Zeichen lang!',//Das Passwort ist weniger als 6 Zeichen lang

        placeholder: 'Bitte eingeben',

        emailRegistration: 'Jetzt registrieren',
        forgetPassTitle: 'Passwort vergessen? ',

        submitBtnTitle: 'Anmelden',
        loginMessage: 'Konto oder Passwort ist falsch! '
    },
    'appForgotPass': {
        navTitle: 'Passwort vergessen',
        navTitleHint: 'Bitte geben Sie Ihre Handynummer ein und erhalten Sie den Bestätigungscode, um ein neues Passwort festzulegen.',
        emailLabel: 'Handynummer',
        emailPlaceholder: 'Bitte geben Sie Ihre Handynummer ein',
        emailErrorText: 'Fehlermeldung!',//Bitte geben Sie Ihre E-Mail-Adresse ein
        emailNullVerify: 'Bitte geben Sie Ihre Handynummer ein!',//Bitte geben Sie Ihre E-Mail-Adresse ein
        emailErrorVerify: 'Bitte geben Sie Ihre Handynummer ein!',//Fehler im E-Mail-Format
        verificationCode: 'Bestätigungscode',
        verificationPlace: 'Bestätigungscode',
        verificationCodeError: 'Bitte geben Sie den Bestätigungscode ein!',
        sendTitle: 'Verifizierungscode senden',
        sendText: 'Erneut bestätigen',
        passwordLabel: 'Neues Passwort',
        passPlaceholder: 'Bitte geben Sie ein neues Passwort ein',
        confirmPassLabel: 'Neues Passwort bestätigen',
        confirmPlace: 'Neues Passwort bestätigen',
        passNullVerify: 'Geben Sie Ihr neues Passwort ein', //Bitte geben Sie Ihr Passwort ein
        passErrorVerify: 'Das Passwort ist weniger als 6 Zeichen lang! ',//Das Passwort ist weniger als 6 Zeichen lang
        passCompareErrorVerify: 'Die zweimal eingegebenen Passwörter sind inkonsistent! ',
        passCompareErrorNull: 'Bitte geben Sie das neue Passwort erneut ein',
        submitBtnTitle: 'Passwort zurücksetzen',
        loginMessage: 'Der Verifizierungscode ist falsch! '
        //emailRegistration: 'E-Mail-Registrierung',
        //getPassTitle: 'Passwort vergessen?',

    },
    'appRegister': {
        navTitle: 'Registrieren',
        navTitleHint: 'Erstellen Sie ein Konto, um auf alle Funktionen zugreifen zu können! ',
        namePlace: 'Bitte eingeben',
        firstNameLabel: 'Name',
        firstNamePlace: 'Bitte eingeben',
        firstNameErrorVerify: 'Bitte geben Sie Ihren Namen ein',
        lastNameLabel: 'Nachname',
        lastNameErrorVerify: 'Bitte geben Sie Ihren Nachnamen ein',
        nickNameLabel: 'Spitzname',
        nickNameErrorVerify: 'Bitte geben Sie Ihren Spitznamen ein',
        emailLabel: 'Mobiltelefonnummer',
        emailPlaceholder: 'Bitte geben Sie Ihre Mobilnummer ein',
        emailErrorText: 'Fehlermeldung!',//Bitte geben Sie Ihre E-Mail-Adresse ein
        emailNullVerify: 'Bitte geben Sie Ihre Mobilnummer ein!',//Bitte geben Sie Ihre E-Mail-Adresse ein
        emailErrorVerify: 'Bitte geben Sie Ihre Mobilnummer ein! ',//E-Mail-Formatfehler
        verificationCode: 'Verifizierungscode',
        verificationPlace: 'Bitte geben Sie den Verifizierungscode ein',
        sendTitle: 'Verifizierungscode senden',
        sendText: 'Countdown',
        verificationCodeError: 'Bitte geben Sie den Verifizierungscode ein! ',
        passwordLabel: 'Passwort',
        passPlaceholder: 'Bitte geben Sie das Passwort ein',
        confirmPassLabel: 'Passwort bestätigen',
        confirmPlace: 'Passwort bestätigen',
        passNullVerify: 'Geben Sie Ihr Passwort ein',//Bitte geben Sie Ihr Passwort ein
        passErrorVerify: 'Das Passwort ist kürzer als 6 Zeichen! ',//Das Passwort ist kürzer als 6 Zeichen
        passCompareErrorVerify: 'Die zweimal eingegebenen Passwörter sind inkonsistent! ',
        passCompareErrorNull: 'Bitte geben Sie das Passwort erneut ein',
        registerHint: 'Sie haben bereits ein Konto? ',
        registerHintLogin: 'Anmelden',
        submitBtnTitle: 'Registrieren',
        loginMessage: 'Der Bestätigungscode ist falsch! '

        // gender: 'Gender',
        // gender1: 'Boy',
        // gender2: 'Girl',
        // gender3: 'Secrecy',
        // genderError: 'Please enter your gender',
    },
    'appGoodsDetail': {
        salesTitle: 'Umsatz',
        payHintTitle1: 'Guthaben',
        payHintTitle2: 'PayPal',
        payHintTitle3: 'Visa',
        conditionLabel: 'Zustand:',
        conditionContent: 'Marken- und kategorieunterstützte Zahlungsmethoden',
        frightLabel: 'Logistik',
        frightContent: 'Lieferung im Durchschnitt innerhalb von 1 bis 3 Tagen',
        agreement: 'Käuferschutzvereinbarung',
        specSelect: 'Bitte wählen Sie eine Spezifikation aus',
        specLabel: 'Spezifikation',
        specOptions: 'bestätigen',
        countLabel: 'Menge',
        commentTitle: 'Kommentar',
        deliverTime: 'Lieferzeit und -tage auswerten',
        salesLabel: 'Verkaufsmenge:',
        productCount: "Produktmenge:",
        collectLabel: 'Abholmenge:',
        averageLabel: 'Durchschnittliche Anzahl Tage:',
        since: 'Mitgliedstag',
        shipper: 'schneller Antwortender',
        responder: 'Schneller Versender',
        reliable: 'zuverlässig',
        btnContact: 'Kontakt',
        enter: 'Schrank',
        followBtn: 'Kollektion',
        guessLike: 'Ich schätze, es gefällt Ihnen',
        buyNow: 'Jetzt kaufen',
        dialogOverviewTitle: 'Übersicht',
        overConditionLabel: 'Zustand',
        overConditionContent: 'neu',
        overBrandLabel: 'Marke',
        overCategoryLabel: 'Kategorie',
        overPayMethodsLabel1: 'Unterstützte Zahlungsmethoden',
        overPayMethodsLabel2: 'Wichtige Informationen',
        overCategoryEnter: 'Bestätigen',
        dialogFreightTitle: 'Logistikvereinbarung',
        dialogAgreementTitle: 'Käuferschutzvereinbarung',
        footerLeft: 'Zum Warenkorb hinzufügen',
        footerRight: 'Jetzt kaufen',
        activeLabel1: 'Gruppenkauf',
        activeLabel2: 'Zweiter Verkauf',
        activeLabel3: 'Vorverkauf',
        flashStateNameE: 'Ende',
        flashStateNameI: 'Laufend',
        flashStateNameS: 'Nicht gestartet',
        flashHintSName: 'Start',
        flashHintEName: 'Beendet',
        flashHintIName: 'Verbleibende Zeit',
        preSaleStateTimeNO: 'Nicht gestartet',
        preSaleStateTimeE: 'Ende',
        preSaleStateTimeS: 'Laufend',
        preSaleHintNoName: 'Start',
        preSaleHintIEName: 'Beendet',
        preSaleHintEName: 'Ende',
        groupHint: 'Person',
        reviewTitle: 'Systemstandard-Bewertung'
    },
    "appShops": {
        select1: 'Umfassend',
        select2: 'Verkaufsmenge',
        select3: 'Neues Produkt',
        select4: 'Preis hoch zu niedrig',
        select5: 'Preis niedrig zu hoch',
        select6: 'Bewertung',
        infoLabel1: 'Verkaufsmenge',
        infoLabel2: 'Produktmenge',
        infoLabel3: 'Abholmenge',
        infoLabel4: 'Lieferzeit auswerten',
        memberLabel1: 'Mitgliedertag',
        memberLabel2: 'Schneller Antwortender',
        memberLabel3: 'Schneller Versender',
        memberLabel4: 'zuverlässig',
        commodity: 'Ware',
        classAll: 'alle',

        // timeDay: 'Evaluate Delivery time & days',
        // productQuantity: 'Product Quantity',
        // shippedQuantity: 'Shipped Quantity',
        // collections: "Number of Collections",
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 商品评论页
    'appProductReviews': {
        navTitle: 'Kommentare',
        reviewTitle: 'Systemstandardkommentare',
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Versionsupgrade, bitte bestätigen?',
        loadingTitle: 'Wird heruntergeladen...',
        systemUpgrade: 'Systemupgrade',
        upgradeImmediately: 'Bestätigen',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: 'Wird geladen', // Wird geladen
        finishedText: 'Keine', //Keine
        loadingText: 'Wird geladen', // Wird geladen
        nullText: 'Noch keine Daten',
        loadingFinished: 'Laden abgeschlossen',
        listNullText: 'Keine mehr',
        pullIngText: 'Zum Aktualisieren nach unten ziehen',
        looseText: 'Zum Aktualisieren loslassen',
        pullingSuccess: 'Pulldown-Laden erfolgreich',
        source: 'bestätigen'
    },

};
export default obj;
